import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";

export default function RemoveMappedShipmentConfirmModal({
  isOpened,
  onModalClose,
  selectedShipmentIdForRemoval,
  setSelectedShipmentIdForRemoval,
}: {
  isOpened: boolean;
  onModalClose: () => void;
  selectedShipmentIdForRemoval: number | undefined;
  setSelectedShipmentIdForRemoval: (v: number | undefined) => void;
}) {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const { id: poId } = useParams<{ id: string }>();

  const orderDetailQuery = ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
    id: Number(poId),
  });

  const {
    mutate: removeMappedShipment,
    ResponseHandler: ResponseHandlerOfRemoveMappedShipment,
  } = ADMIN_ORDER_QUERY.useRemoveMappedShipment({
    poId: Number(poId),
    onSuccess: () => {
      showSnackbar("성공적으로 연결을 해제했습니다.");
      setSelectedShipmentIdForRemoval(undefined);
      onModalClose();

      return queryClient.invalidateQueries(orderDetailQuery);
    },
  });

  return (
    <>
      <Modal
        isOpened={isOpened}
        handleClose={onModalClose}
        modalBody={
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography className="sads" variant="h6">
              연결을 해제하시겠습니까?
            </Typography>

            <Button
              variant="contained"
              fullWidth
              className="sads"
              onClick={() => {
                if (!selectedShipmentIdForRemoval) return;

                removeMappedShipment({
                  shipmentId: selectedShipmentIdForRemoval,
                });
              }}
            >
              연결 해제
            </Button>
          </Box>
        }
      />

      {ResponseHandlerOfRemoveMappedShipment}
    </>
  );
}
