import { useParams } from "react-router-dom";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import ADMIN_ORDER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";

import AdminOrderDetailDescriptionCard from "./AdminOrderDetailDescriptionCard";

const OrderDetail = () => {
  const { id }: { id: string } = useParams();

  const { data: orderDetail } = ADMIN_ORDER_QUERY.useGetAdminOrderDetail({
    id: Number(id),
  });

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  return (
    <Layout title="발주 상세 정보">
      {orderDetail && (
        <AdminOrderDetailDescriptionCard
          orderDetail={orderDetail}
          portList={portList}
        />
      )}
    </Layout>
  );
};

export default OrderDetail;
