import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";

import DepositManagementTable from "./DepositManagementTable";

function DepositManagement() {
  return (
    <Layout>
      <DepositManagementTable />
    </Layout>
  );
}

export default DepositManagement;
