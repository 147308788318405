import { Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

import CompanyTable from "./CompanyTable";
import SearchTeamName from "./SearchTeamName";

function MatchBidIdModal({
  showsMatchBidIdModal,
  setShowsMatchBidIdModal,
  companyId,
  paymentInvoiceId,
  setCompanyId,
  depositAmount,
  refetchSettlementDepositList,
}: {
  showsMatchBidIdModal: boolean;
  setShowsMatchBidIdModal: Dispatch<SetStateAction<boolean>>;
  companyId: number;
  paymentInvoiceId: number;
  setCompanyId: Dispatch<SetStateAction<number>>;
  depositAmount: number;
  refetchSettlementDepositList: () => void;
}) {
  const {
    data: settlementDepositCompanies,
    refetch: refetchSettlementDepositCompanies,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementDepositCompanies({
    companyId,
    paymentInvoiceId,
  });

  const handleModalClose = () => {
    setShowsMatchBidIdModal(false);
    refetchSettlementDepositList();
  };

  const isCompanySelectedButHasNoInvoiceToConfirmDeposit =
    settlementDepositCompanies &&
    settlementDepositCompanies.length === 0 &&
    !!companyId;

  const hasDepositToSettle =
    settlementDepositCompanies && settlementDepositCompanies.length > 0;

  return (
    <Modal
      isOpened={showsMatchBidIdModal}
      handleClose={handleModalClose}
      modalBody={
        <Box display="flex" flexDirection="column" gap={3} minWidth={400}>
          <Typography variant="h6" className="sads">
            입금 의뢰번호 매핑{" "}
            {!companyId && (
              <Typography variant="body1" className="sads" display="inline">
                (회사명을 검색 후 선택해 주세요.)
              </Typography>
            )}
          </Typography>

          <SearchTeamName companyId={companyId} setCompanyId={setCompanyId} />

          {isCompanySelectedButHasNoInvoiceToConfirmDeposit && (
            <Typography className="sads">
              입금확인이 필요한 거래명세서가 없습니다.
            </Typography>
          )}

          {hasDepositToSettle && (
            <CompanyTable
              settlementDepositCompanies={settlementDepositCompanies}
              depositAmount={depositAmount}
              paymentInvoiceId={paymentInvoiceId}
              handleModalClose={handleModalClose}
              refetchSettlementDepositCompanies={
                refetchSettlementDepositCompanies
              }
            />
          )}
        </Box>
      }
    />
  );
}

export default MatchBidIdModal;
