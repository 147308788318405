import { Dispatch, SetStateAction, useMemo, useState } from "react";
import React from "react";
import { Checkbox, Typography } from "@mui/material";

import Table from "@sellernote/_shared/src/admin-ui/components/Table";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import {
  changeBidProjectStatusNameToKr,
  getSupplyValueInUnit,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { changeBidStatusNameToKr } from "@sellernote/_shared/src/utils/forwarding/bid";

import { SelectedShipment } from "../types";

import HEAD_CELL_LIST from "./HEAD_CELL_LIST";

export default function ShipmentTable({
  selectedList,
  setSelectedList,
  teamId,
  searchType,
  searchKeyword,
}: {
  selectedList: SelectedShipment[];
  setSelectedList: Dispatch<SetStateAction<SelectedShipment[]>>;
  teamId: number;
  searchType: string;
  searchKeyword: string;
}) {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const searchPayload = (() => {
    if (!searchKeyword) return {};

    return { [searchType]: searchKeyword };
  })();

  const debouncedSearchPayload = useDebounce(searchPayload, 1000);

  const { data: shipment, ResponseHandler: ResponseHandlerOfAdminBidList } =
    ADMIN_BID_QUERY.useGetAdminBidList({
      params: {
        order: "DESC",
        page,
        perPage,
        teamId,
        ...debouncedSearchPayload,
      },
      hidesLoading: true,
    });

  const getFormattedItemGroupNames = (itemGroupNameList: string[]): string => {
    if (!itemGroupNameList.length) return "-";
    if (itemGroupNameList.length === 1) return itemGroupNameList[0];

    return `${itemGroupNameList[0]} 외 ${itemGroupNameList.length - 1}`;
  };

  const rowList = useMemo(() => {
    if (!shipment) return [];

    return shipment.list.map((item) => ({
      select: (
        <Checkbox
          checked={selectedList.some(
            ({ shipmentId }) => shipmentId === item.id
          )}
          onChange={({ target }) => {
            if (target.checked) {
              const hasMappedPO = Boolean(item.purchaseOrders.length);

              setSelectedList((prev) => [
                ...prev,
                { shipmentId: item.id, isMapped: hasMappedPO },
              ]);
            } else {
              setSelectedList((prev) =>
                prev.filter(({ shipmentId }) => shipmentId !== item.id)
              );
            }
          }}
        />
      ),
      shipmentId: item.id,
      poId: (
        <>
          {item.purchaseOrders.length
            ? item.purchaseOrders.map(({ poNumber }, index) => {
                return (
                  <React.Fragment key={poNumber}>
                    {index > 0 && <br />}
                    {poNumber}
                  </React.Fragment>
                );
              })
            : "-"}
        </>
      ),
      cargoInfo: (
        <Typography variant="body2" className="sads">
          {getFormattedItemGroupNames(item.itemGroupNameList)}
          <br />
          {item.freightType}
          <br />
          {item.supply && getSupplyValueInUnit(item.freightType, item.supply)}
        </Typography>
      ),
      status: changeBidStatusNameToKr(item.status),
      detailedStatus: changeBidProjectStatusNameToKr(item.projectStatus) || "-",
      createdAt: formatDate({
        date: item.createdAt,
        type: "YY_MM_DD_ddd_HH_mm",
      }),
    }));
  }, [shipment, selectedList, setSelectedList]);

  return (
    <>
      <Table
        headCells={HEAD_CELL_LIST}
        rows={rowList}
        pagination={{
          totalCount: shipment?.total ?? 0,
          perPage,
          setPerPage,
          currentPage: page,
          setCurrentPage: setPage,
        }}
      />

      {ResponseHandlerOfAdminBidList}
    </>
  );
}
