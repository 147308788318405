import { useMemo, useState } from "react";
import { Typography } from "@mui/material";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/LegacyTable";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import { GET_ADMIN_INLAND_FARES_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminInalndFares";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared/src/hooks/admin/useSearchWithTerm";
import ADMIN_INLAND_FARES_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_INLAND_FARES_QUERY";
import { InlandFclFares } from "@sellernote/_shared/src/types/forwarding/adminInlandFares";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

type CellId = keyof InlandFclFares | "startPort" | "endAddress";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_INLAND_FARES_REQ_SEARCH_KIND>[] =
  [
    {
      label: "주소",
      value: "address",
    },
    {
      label: "zoneId",
      value: "zoneId",
    },
  ];

const InlandFareFclList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  //TODO: 이관 브랜치에서 따로 작업한 전용 hooks가 있음 나중에 병합 시 수정
  const termSearchTypeKeyObject = useMemo(() => {
    if (termSearchType?.value && debouncedSearchTerm) {
      const searchObject: { [key: string]: unknown } = {};
      searchObject[termSearchType?.value] = debouncedSearchTerm;
      return searchObject;
    }
    return undefined;
  }, [debouncedSearchTerm, termSearchType?.value]);

  const { data: inlandFareList } =
    ADMIN_INLAND_FARES_QUERY.useGetAdminInlandFclFares({
      page: 0,
      perPage: 25,
      ...termSearchTypeKeyObject,
    });

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "번호",
        width: 100,
      },
      {
        id: "startPort",
        disablePadding: false,
        label: "출발지",
        width: 100,
      },
      {
        id: "endAddress",
        disablePadding: false,
        label: "도착지",
        width: 200,
      },
      {
        id: "DRY20",
        disablePadding: false,
        label: "20DRY",
        width: 120,
      },
      {
        id: "DRY40",
        disablePadding: false,
        label: "40DRY",
        width: 120,
      },
      {
        id: "HQ40",
        disablePadding: false,
        label: "40HQ",
        width: 120,
      },
      {
        id: "return20A",
        disablePadding: false,
        label: "인천구항20",
        width: 100,
      },
      {
        id: "return40A",
        disablePadding: false,
        label: "인천구항40",
        width: 100,
      },
      {
        id: "return20B",
        disablePadding: false,
        label: "인천신항20",
        width: 100,
      },
      {
        id: "return40B",
        disablePadding: false,
        label: "인천신항40",
        width: 100,
      },
      {
        id: "return20C",
        disablePadding: false,
        label: "의왕20",
        width: 100,
      },
      {
        id: "return40C",
        disablePadding: false,
        label: "의왕40",
        width: 100,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!inlandFareList?.list) return [];

    return inlandFareList?.list.map((inlandFareListItem) => {
      const row: LegacyTableBodyRow<CellId> = {
        id: inlandFareListItem.id,
        startPort: inlandFareListItem.port.name,
        endAddress: inlandFareListItem.zone.name,
        DRY20: toThousandUnitFormat(inlandFareListItem.DRY20),
        DRY40: toThousandUnitFormat(inlandFareListItem.DRY40),
        HQ40: toThousandUnitFormat(inlandFareListItem.HQ40),
        return20A: toThousandUnitFormat(inlandFareListItem.return20A),
        return40A: toThousandUnitFormat(inlandFareListItem.return40A),
        return20B: toThousandUnitFormat(inlandFareListItem.return20B),
        return40B: toThousandUnitFormat(inlandFareListItem.return40B),
        return20C: toThousandUnitFormat(inlandFareListItem.return20C),
        return40C: toThousandUnitFormat(inlandFareListItem.return40C),
      };
      return row;
    });
  }, [inlandFareList?.list]);

  return (
    <Layout breadcrumbs={["내륙요금관리"]} title={"FCL"}>
      <LegacyTable
        toolbarItems={{
          left: [TermSearchPanel],
          right: [
            <Typography
              key="text"
              sx={{ fontSize: 14 }}
              color="error"
              gutterBottom
            >
              Drop-off Charge: 20 - 50,000 / 40 - 70,000
            </Typography>,
          ],
        }}
        title="FCL 내륙요금 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: inlandFareList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />
    </Layout>
  );
};

export default InlandFareFclList;
