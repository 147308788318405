const HEAD_CELL_LIST = [
  { id: "select", label: "선택", disablePadding: false },
  { id: "shipmentId", label: "운송의뢰번호", disablePadding: false },
  { id: "poId", label: "PO번호", disablePadding: false },
  { id: "cargoInfo", label: "화물정보", disablePadding: false },
  { id: "status", label: "상태", disablePadding: false },
  { id: "detailedStatus", label: "세부상태", disablePadding: false },
  { id: "createdAt", label: "생성일", disablePadding: false },
];

export default HEAD_CELL_LIST;
