import { useCallback, useMemo, useRef, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { GET_ADMIN_BID_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "@sellernote/_shared/src/constants/forwarding/adminZIndexLevelRecord";
import { TableHeadFilterOption } from "@sellernote/_shared/src/hooks/admin/useTableHeadFilter";
import useTableHeadFilterUnstyled from "@sellernote/_shared/src/hooks/admin/useTableHeadFilterUnstyled";
import { IsNotInProgressStatus } from "@sellernote/_shared/src/types/forwarding/adminOrder";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";

export default function useInProgressFilter(
  bidFilterData: GET_ADMIN_BID_LIST_FILTER
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isCanceled, setIsCanceled] = useState<boolean>(true);

  const isNotInProgressHistory = useMemo(() => {
    if (bidFilterData.isNotInProgress) {
      return { isNotInProgress: bidFilterData.isNotInProgress };
    }
  }, [bidFilterData]);

  const [headFilterData, setHeadFilterData] = useState<{
    isNotInProgress: IsNotInProgressStatus[];
  } | null>(isNotInProgressHistory ?? null);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  const anchorElRef = useRef<HTMLDivElement>(null);

  const isNotInProgressFilterOptions: TableHeadFilterOption<IsNotInProgressStatus>[] =
    [
      { label: "예", value: "notYet" },
      { label: "아니오", value: "done" },
      { label: "공란", value: "init" },
    ];

  const {
    FilterPanel: IsNotInProgressFilterPanel,
    draftFilter: isNotInProgressFilterData,
    handleFilterReset: IsNotInProgressReset,
  } = useTableHeadFilterUnstyled({
    filterOptions: isNotInProgressFilterOptions,
  });

  const isNotInProgressFilter = useMemo(() => {
    return {
      isNotInProgress: isNotInProgressFilterData,
    };
  }, [isNotInProgressFilterData]);

  const handleSomewhereClick = useCallback(() => {
    setAnchorEl(null);
    setIsCanceled(true);
  }, []);

  const handleSubmitFilter = useCallback(() => {
    if (!isNotInProgressFilter) return setAnchorEl(null);

    if (isNotInProgressFilter) {
      setHeadFilterData(isNotInProgressFilter);
      setIsCanceled(false);
      setAnchorEl(null);
    }
  }, [isNotInProgressFilter]);

  const handleFilterOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (isCanceled) {
        if (isNotInProgressHistory) {
          IsNotInProgressReset(isNotInProgressHistory.isNotInProgress);
        }

        return setIsCanceled(false);
      } else {
        isNotInProgressFilter &&
          IsNotInProgressReset(isNotInProgressFilter?.isNotInProgress);
        return setIsCanceled(false);
      }
    },

    [
      anchorEl,
      isNotInProgressHistory,
      IsNotInProgressReset,

      isCanceled,
      isNotInProgressFilter,
    ]
  );

  const filterTriggerPosition = (() => {
    const target = anchorElRef.current;
    if (!target) {
      return null;
    }

    return target.getClientRects()[0];
  })();

  const handleReset = useCallback(() => {
    IsNotInProgressReset([]);
    setHeadFilterData(null);
  }, [IsNotInProgressReset]);

  const handleInsideReset = useCallback(() => {
    IsNotInProgressReset([]);
  }, [IsNotInProgressReset]);

  const InProgressFilter = useMemo(() => {
    const SingleFilter = () => {
      return (
        <>
          <IconButton
            aria-owns={id}
            aria-describedby={id}
            aria-haspopup={true}
            type="button"
            onClick={handleFilterOpen}
            color={
              !isEmptyObjectOrArray(headFilterData?.isNotInProgress || {})
                ? "primary"
                : "inherit"
            }
          >
            <FilterAltIcon />
          </IconButton>

          <Dialog open={open}>
            <Popper
              key={id}
              placement="bottom-start"
              anchorEl={anchorEl}
              id={id}
              open={open}
              transition
              style={{
                zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
              }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={handleSomewhereClick}
                  disableReactTree
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      sx={{
                        padding: 2,
                        width: "100%",
                        minWidth: "160px",
                        position: "absolute",
                        maxHeight: "300px",
                        overflow: "scroll",
                        top: (filterTriggerPosition?.top ?? 0) + 17,
                        left: (filterTriggerPosition?.left ?? 0) - 40,
                        right: "100%",
                        display: "flex",
                        zIndex:
                          FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          width: 160,
                          height: 50,
                          position: "fixed",
                          top: 150,
                          left: -40,
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                        }}
                      >
                        <IconButton onClick={() => handleInsideReset()}>
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-flex" }}
                          >
                            <RestartAltIcon />
                            reset
                          </Typography>
                        </IconButton>
                        <Button variant="outlined" onClick={handleSubmitFilter}>
                          적용
                        </Button>
                      </Box>
                      <Grid>{IsNotInProgressFilterPanel}</Grid>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Dialog>
        </>
      );
    };

    return <Box>{SingleFilter()}</Box>;
  }, [
    id,
    open,
    filterTriggerPosition?.left,
    filterTriggerPosition?.top,
    handleFilterOpen,
    anchorEl,
    handleSomewhereClick,
    IsNotInProgressFilterPanel,
    headFilterData,
    handleInsideReset,
    handleSubmitFilter,
  ]);

  return {
    InProgressFilter,
    headFilterData,
    handleReset,
  };
}
