import { GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { BANK_NAME_FILTER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminSettlement";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared/src/hooks/admin/useGetObjectWithTermSearchTypeKey";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared/src/hooks/admin/useSearchWithDate";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared/src/hooks/admin/useSearchWithTerm";
import useTableHeadFilter from "@sellernote/_shared/src/hooks/admin/useTableHeadFilter";
import { TableHeadFilterOption } from "@sellernote/_shared/src/hooks/admin/useTableHeadFilterUnstyled";
import { InvoiceResult } from "@sellernote/_shared/src/types/forwarding/trello";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "입금자명",
      value: "depositName",
    },
    {
      label: "입금액",
      value: "depositAmount",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<"depositDate">[] = [
  {
    label: "입금일",
    value: "depositDate",
  },
];

const depositResultFilterOptions: TableHeadFilterOption<InvoiceResult>[] = [
  { label: "초과", value: "over" },
  { label: "완료", value: "complete" },
];

const invoiceResultFilterOptions: TableHeadFilterOption<InvoiceResult>[] = [
  { label: "미수", value: "unpaid" },
  { label: "완료", value: "complete" },
];

export default function useTableFilter() {
  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
    });

  // TODO: useTableHeadFilter를 _shared-for-forwarding-admin으로 옮겨야 함 실험실 테이블 작업 완료 후 변경 작업 예정
  const { FilterPanel: BankNameFilterPanel, filter: bankNameFilterData } =
    useTableHeadFilter({
      filterOptions: BANK_NAME_FILTER_OPTION_LIST,
    });

  const {
    FilterPanel: DepositResultFilterPanel,
    filter: depositResultFilterData,
  } = useTableHeadFilter({
    filterOptions: depositResultFilterOptions,
  });

  const {
    FilterPanel: InvoiceResultFilterPanel,
    filter: invoiceResultFilterData,
  } = useTableHeadFilter({
    filterOptions: invoiceResultFilterOptions,
  });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  return {
    /** 셀렉트 + 텍스트필드 필터(입금자명, 입금액)  */
    objectWithTermSearchTypeKey,
    TermSearchPanel,

    /** 입금일 날짜 필터 */
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,

    /** 은행명 테이블 헤드 필터 */
    BankNameFilterPanel,
    bankNameFilterData,

    /** 매핑결과 헤드 필터 */
    DepositResultFilterPanel,
    depositResultFilterData,

    /** 청구서별 매핑 상태 */
    InvoiceResultFilterPanel,
    invoiceResultFilterData,
  };
}
