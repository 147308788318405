import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtom } from "jotai";
import { FORWARDING_ADMIN_USER_ATOMS } from "jotaiStates/user";

import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

export default function ForwardingManagerFilter() {
  const [{ forwardingManagerId }, setUserTableFilterData] = useAtom(
    FORWARDING_ADMIN_USER_ATOMS.USER_TABLE_FILTER_DATA
  );

  const handleForwardingManagerIdChange = (forwardingManagerId: number) => {
    setUserTableFilterData((prev) => ({
      ...prev,
      forwardingManagerId,
    }));
  };

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const forwardingManagerList = (adminUserList ?? []).filter((manager) => {
    if (!adminUserList) return [];

    return manager.isForwardingManager;
  });

  const menuItemList = [{ id: 0, name: "전체" }, ...forwardingManagerList];

  return (
    <Box>
      <FormControl size="small" sx={{ width: 150 }}>
        <InputLabel>포워딩 매니저</InputLabel>

        <Select
          value={forwardingManagerId || 0}
          onChange={(e) => {
            handleForwardingManagerIdChange(Number(e.target.value));
          }}
          label="포워딩 매니저"
        >
          {menuItemList.map((manager) => (
            <MenuItem key={manager.id} value={manager.id}>
              {manager.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
