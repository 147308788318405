import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  IconButton,
  Tooltip as MuiTooltip,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/LegacyTable";
import { GET_ADMIN_ORDER_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminOrder";
import { I18N_KEY_BY_PO_STATUS } from "@sellernote/_shared/src/constants/forwarding/purchaseOrder";
import { BidTableHeadPanelType } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  AdminOrderListItem,
  POProjectStatusArrayItem,
} from "@sellernote/_shared/src/types/forwarding/adminOrder";
import InfoFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/InfoFilledIcon";
import Tooltip from "@sellernote/_sds-v2/src/components/Tooltip";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import OrderMemo from "./OrderMemo";

const OrderManagementTable = ({
  orderList,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  TableHeadPanel,
  saveFilterDataRefCallback,
  isLoadingForOrderList,
  refetchOrderList,
  handleFilterReset,
}: {
  orderList: GET_ADMIN_ORDER_LIST_RES | undefined;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  TableHeadPanel: Partial<Record<BidTableHeadPanelType, JSX.Element>>;
  saveFilterDataRefCallback: () => void;
  isLoadingForOrderList: boolean;
  refetchOrderList: () => void;
  handleFilterReset: () => void;
}) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  type CellId = keyof AdminOrderListItem | "reset";

  const history = useHistory();

  const changeIsInProgressStatusToKr = useCallback((v: AdminOrderListItem) => {
    switch (v.isNotInProgress) {
      case true:
        return "예";
      case false:
        return "아니오";
      case null:
        return "";
      default:
        return "";
    }
  }, []);

  const getProjectStatusFilterTypography = useCallback((v) => {
    const completedBid = v.poFinishedProjectStatus.find(
      (item: POProjectStatusArrayItem) => item.status === "completed"
    );
    const failedBid = v.poFinishedProjectStatus.find(
      (item: POProjectStatusArrayItem) => item.status === "failed"
    );
    const canceledBid = v.poFinishedProjectStatus.find(
      (item: POProjectStatusArrayItem) => item.status === "canceled"
    );

    const completedResult = `${completedBid?.length} / ${v.bidLength}`;
    const failedResult = `${failedBid?.length} / ${v.bidLength}`;
    const canceledResult = `${canceledBid?.length} / ${v.bidLength}`;

    return (
      <>
        <Typography variant="body2" color={blue[500]}>
          운송 완료 : {completedResult}
        </Typography>
        <Typography variant="body2">취소 : {canceledResult}</Typography>
        <Typography variant="body2">거절 : {failedResult}</Typography>
      </>
    );
  }, []);

  const getPOWaitingStatus = useCallback((v) => {
    return v.poProjectStatus === "contactPartner"
      ? "운송의뢰 생성 전"
      : "파트너 컨택 전";
  }, []);

  const getPODetailStatus = useCallback(
    (v: AdminOrderListItem) => {
      switch (v.poStatus) {
        case "registered":
          return "";
        case "waiting":
          return getPOWaitingStatus(v);
        case "inProgress":
          return getProjectStatusFilterTypography(v);
        case "finished":
          return getProjectStatusFilterTypography(v);
        default:
          return "-";
      }
    },
    [getProjectStatusFilterTypography, getPOWaitingStatus]
  );

  const getMotherPOTypography = useCallback((v) => {
    return (
      <>
        <Typography variant="body2" textAlign={"center"}>
          {v.subPoNumber ? `NY-${v.subPoNumber}` : "-"}
        </Typography>

        <Typography variant="body2" textAlign={"center"}>
          ({v.poNumber})
        </Typography>
      </>
    );
  }, []);

  const getProductName = (productList: AdminOrderListItem["productNames"]) => {
    if (!productList.length) return "-";

    if (productList.length === 1) return productList[0];

    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body1" className="sads">
          {productList[0]} 외 {productList.length - 1}건
        </Typography>

        <Tooltip
          desc={
            <>
              {productList.map((product, index) => {
                return (
                  <Typography key={product} className="sads">
                    품목{index + 1}: {product}
                  </Typography>
                );
              })}
            </>
          }
          position="topLeft"
        >
          <InfoFilledIcon width={16} height={16} color={COLOR.bk_80} />
        </Tooltip>
      </Box>
    );
  };

  const rows = useMemo(() => {
    if (!orderList?.list) return [];

    return orderList?.list.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        handleRowClick: () =>
          history.push(
            pathname.includes("/sub")
              ? `/order/sub/${v.poId}`
              : `/order/${v.poId}`,
            { state: { status: v.poStatus } }
          ),
        reset: "",
        poId: v.poId,
        userCompany: v.userCompany || "",
        teamName: v.teamName,
        managerName: v.managerName,
        poNumber: pathname.includes("/sub")
          ? getMotherPOTypography(v)
          : v.poNumber,
        productName: getProductName(v.productNames),
        exchangeRate:
          v.currency && v.invoiceValue
            ? `${v.currency} ${v.invoiceValue.toLocaleString()}`
            : "",
        memo: <OrderMemo memo={v.memo} />,
        isNotInProgress: pathname.includes("/sub")
          ? ""
          : changeIsInProgressStatusToKr(v),
        poStatus: pathname.includes("/sub")
          ? "확인 중"
          : t(I18N_KEY_BY_PO_STATUS[v.poStatus]) || "-",
        poProjectStatus: pathname.includes("/sub")
          ? "운송의뢰 생성 전"
          : getPODetailStatus(v),
      };

      return row;
    });
  }, [
    orderList?.list,
    pathname,
    getMotherPOTypography,
    changeIsInProgressStatusToKr,
    t,
    getPODetailStatus,
    history,
  ]);

  const RefreshButton = useMemo(() => {
    return (
      <MuiTooltip title="리스트 데이터 새로고침">
        <IconButton onClick={refetchOrderList}>
          <RestartAltIcon />
        </IconButton>
      </MuiTooltip>
    );
  }, [refetchOrderList]);

  const HeadCellsWithFilter = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "reset",
        disablePadding: false,
        label: RefreshButton,
        width: 10,
      },
      {
        id: "poId",
        disablePadding: false,
        label: "등록번호",
        width: 85,
      },

      {
        id: "userCompany",
        disablePadding: false,
        label: "회사명",
        width: 110,
      },
      {
        id: "teamName",
        disablePadding: false,
        label: "팀명",
        width: 110,
      },
      {
        id: "managerName",
        disablePadding: false,
        label: "포워딩담당자",
        width: 120,
        filter: TableHeadPanel.ForwardingManagerFilter,
      },
      {
        id: "poNumber",
        disablePadding: false,
        label: "PO번호",
        width: 200,
      },
      {
        id: "productName",
        disablePadding: false,
        label: "상품명",
        width: 200,
      },
      {
        id: "exchangeRate",
        disablePadding: false,
        label: "인보이스 밸류",
        width: 150,
      },
      {
        id: "memo",
        disablePadding: false,
        label: "메모",
        width: 240,
      },
      {
        id: "isNotInProgress",
        disablePadding: false,
        label: pathname.includes("/sub") ? "" : "미진행의뢰 여부",
        width: pathname.includes("/sub") ? 10 : 140,
        filter: pathname.includes("/sub")
          ? null
          : TableHeadPanel.InProgressFilter,
      },
      {
        id: "poStatus",
        disablePadding: false,
        label: "상태",
        width: 120,
      },
      {
        id: "poProjectStatus",
        disablePadding: false,
        label: "세부 상태",
        width: 150,
      },
    ];
    return headCells;
  }, [
    RefreshButton,
    TableHeadPanel.ForwardingManagerFilter,
    TableHeadPanel.InProgressFilter,
    pathname,
  ]);

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: orderList?.total || 0,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
  };

  return (
    <Box ref={saveFilterDataRefCallback}>
      <LegacyTable
        headCells={HeadCellsWithFilter}
        rows={rows}
        pagination={pagination}
        toolbarItems={{
          left: [
            <Typography key="total">총 {orderList?.total || 0}건</Typography>,
          ],
        }}
        isLoading={isLoadingForOrderList}
        resetHeadFilter={handleFilterReset}
      />
    </Box>
  );
};

export default OrderManagementTable;
