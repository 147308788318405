import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";

import { SelectedShipment } from "./types";

import MappingButton from "./MappingButton";
import ShipmentTable from "./ShipmentTable";

export default function ShipmentMappingModal({
  teamId,
  onModalClose,
}: {
  teamId: number;
  onModalClose: () => void;
}) {
  const [searchType, setSearchType] = useState("id");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedList, setSelectedList] = useState<SelectedShipment[]>([]);

  return (
    <Modal
      isOpened
      handleClose={onModalClose}
      modalBody={
        <Box width={1400} display="flex" flexDirection="column" gap={4}>
          <Typography variant="h6" className="sads">
            운송 의뢰 추가 매핑
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" className="sads">
              총 {selectedList.length}개의 운송 의뢰가 선택되었습니다.
              <br />
              선택한 운송 의뢰:
              {selectedList.map((item) => (
                <Chip
                  key={item.shipmentId}
                  label={item.shipmentId}
                  size="small"
                  className="sads"
                  onDelete={() => {
                    setSelectedList((prev) =>
                      prev.filter((prev) => prev.shipmentId !== item.shipmentId)
                    );
                  }}
                  deleteIcon={<CloseIcon />}
                  sx={{ ml: 1 }}
                />
              ))}
            </Typography>

            <Box>
              <FormControl size="small">
                <InputLabel>검색 대상</InputLabel>

                <Select
                  label="검색 대상"
                  value={searchType}
                  onChange={({ target }) => {
                    setSearchKeyword("");
                    setSearchType(target.value);
                  }}
                >
                  <MenuItem value="id">의뢰번호</MenuItem>
                  <MenuItem value="userName">담당자명</MenuItem>
                  <MenuItem value="startPortName">출발항</MenuItem>
                </Select>
              </FormControl>

              <TextField
                placeholder="검색어"
                className="sads"
                size="small"
                value={searchKeyword}
                onChange={({ target }) => setSearchKeyword(target.value)}
                sx={{ ml: 1 }}
              />
            </Box>
          </Box>

          <ShipmentTable
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            teamId={teamId}
            searchType={searchType}
            searchKeyword={searchKeyword}
          />

          <MappingButton
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            onModalClose={onModalClose}
          />
        </Box>
      }
    />
  );
}
