import { ChangeEvent, useMemo, useState } from "react";
import { Box, Card, Typography } from "@mui/material";

import Table from "@sellernote/_shared/src/admin-ui/components/Table";
import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";

const COLUMN_LIST = [
  {
    id: "id",
    disablePadding: false,
    label: "번호",
  },
  {
    id: "continentNameKR",
    disablePadding: false,
    label: "대륙",
  },
  {
    id: "name",
    disablePadding: false,
    label: "국가코드",
  },
  {
    id: "nameKR",
    disablePadding: false,
    label: "국가",
  },
  {
    id: "order",
    disablePadding: false,
    label: "순서",
  },
];

const CountryList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const rowList = useMemo(() => {
    if (!countryList) return [];

    return countryList
      .filter((country) => country.nameKR.includes(searchText))
      .map((country) => ({
        id: country.id,
        continentNameKR: "-",
        name: country.name,
        nameKR: country.nameKR,
        order: "-",
      }))
      .filter((_, i) => {
        const startIndex = currentPage * 25;
        const endIndex = startIndex + 25;
        return i >= startIndex && i < endIndex;
      });
  }, [countryList, currentPage, searchText]);

  const totalLength = useMemo(() => {
    if (!countryList) return 0;

    return countryList.filter(({ nameKR }) => nameKR.includes(searchText))
      .length;
  }, [searchText, countryList]);

  return (
    <Layout breadcrumbs={["국가 관리"]} title={"국가 정보"}>
      <Card>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          p={1}
        >
          <Typography component="span">{totalLength}개</Typography>

          <TextField size="small" onChange={handleSearch} label="국가" />
        </Box>

        <Table
          headCells={COLUMN_LIST}
          rows={rowList}
          pagination={{
            rowsPerPageOptions: [],
            totalCount: totalLength,
            currentPage,
            perPage: 25,
            setCurrentPage,
            setPerPage: () => {},
          }}
        />
      </Card>
    </Layout>
  );
};

export default CountryList;
