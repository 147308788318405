import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import useForwardingManagerIdFilter from "@sellernote/_shared/src/hooks/admin/useForwardingManagerIdFilter";
import { FilterDataKey } from "@sellernote/_shared/src/types/forwarding/common";

import { SHIPDA_ADMIN_FILTER_ATOMS } from "../../../states/filters";
import useInProgressFilter from "./useInProgressFilter";

export default function useTableHeadPanel(filterDataKey: FilterDataKey) {
  const { pathname } = useLocation();

  const filterData = useRecoilValue(SHIPDA_ADMIN_FILTER_ATOMS[filterDataKey]);

  const {
    ForwardingManagerFilter,
    headFilterData: ForwardingManagerIdFilterData,
    handleReset: ForwardingManagerIdReset,
  } = useForwardingManagerIdFilter(filterData.forwardingManagerIdList);

  const {
    InProgressFilter,
    headFilterData: InProgressFilterData,
    handleReset: InProgressReset,
  } = useInProgressFilter(filterData);

  const tableHeadFilterData = useMemo(() => {
    if (pathname.includes("/sub")) {
      return {
        ...ForwardingManagerIdFilterData,
      };
    }
    return {
      ...ForwardingManagerIdFilterData,

      ...InProgressFilterData,
    };
  }, [ForwardingManagerIdFilterData, InProgressFilterData, pathname]);

  const handleFilterReset = () => {
    ForwardingManagerIdReset();
    InProgressReset();
  };

  const TableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    InProgressFilter,
  };
  const NotYetTableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
  };
  return {
    tableHeadFilterData,
    TableHeadPanel,
    NotYetTableHeadPanel,
    handleFilterReset,
  };
}
