import { useMemo } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Tooltip, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/Table";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { FinancialDepositHistory } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeInvoiceTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

type DepositHistoryTableCellId = keyof FinancialDepositHistory;

function MatchedInvoiceModal({
  invoiceId,
  showsMatchedInvoiceModal,
  setShowsMatchedInvoiceModal,
  shipmentId,
}: {
  invoiceId: number;
  showsMatchedInvoiceModal: boolean;
  setShowsMatchedInvoiceModal: (value: boolean) => void;
  shipmentId: number;
}) {
  const { data: matchedInvoiceDepositHistoryData } =
    TRELLO_BID_QUERY.useGetFinancialDepositHistory({
      invoiceId,
      params: {
        page: 0,
        isMapped: true,
      },
    });

  const rows = useMemo(() => {
    if (!matchedInvoiceDepositHistoryData?.list) return [];

    return matchedInvoiceDepositHistoryData.list.map((v) => {
      const row: TableBodyRow<DepositHistoryTableCellId> = {
        transactionDateTime: v.transactionDateTime,
        bankName: v.bankName,
        depositName: v.depositName,
        currency: v.currency,
        depositAmount: toThousandUnitFormat(v.depositAmount),
        remark: v.remarkFinalPriceTotal !== 0 && (
          <Grid container direction="column" textAlign="right">
            <Grid item>{toThousandUnitFormat(v.remarkFinalPriceTotal)}</Grid>

            {v.remark?.map((n) => {
              return (
                <Grid item key={n.invoiceId}>
                  <Typography variant="body2">{`(${
                    n.bidId
                  } ${changeInvoiceTypeToKr(n.invoiceType)})`}</Typography>
                </Grid>
              );
            })}
          </Grid>
        ),
      };

      return row;
    });
  }, [matchedInvoiceDepositHistoryData?.list]);

  const headCells: TableHeadCell<DepositHistoryTableCellId>[] = useMemo(() => {
    return [
      {
        id: "transactionDateTime",
        disablePadding: false,
        label: "입금일시",
        width: 120,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "수취은행",
      },
      {
        id: "depositName",
        disablePadding: false,
        label: "입금자명",
        width: 150,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
      },
      {
        id: "depositAmount",
        disablePadding: false,
        label: "입금액",
      },
      {
        id: "remark",
        disablePadding: false,
        label: (
          <Tooltip title="입금액이 다른 거래명세서에 매핑된 경우 내용을 알 수 있습니다.">
            <Grid container alignItems={"baseline"}>
              <Typography variant="body2">비고</Typography>

              <InfoOutlinedIcon sx={{ fontSize: "12px", cursor: "pointer" }} />
            </Grid>
          </Tooltip>
        ),
      },
    ];
  }, []);

  return (
    <Modal
      handleClose={() => {
        setShowsMatchedInvoiceModal(false);
      }}
      isOpened={showsMatchedInvoiceModal}
      modalBody={
        <Grid container direction="column" spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" component="div">
              {shipmentId} 입금 내역
            </Typography>
          </Grid>

          <Grid item>
            <Table
              toolbarItems={{
                left: [
                  <Box key={"sub-title"}>
                    <Typography variant="body2" component="span">
                      거래명세서에 등록된 입금내역입니다.
                    </Typography>
                  </Box>,
                ],
              }}
              headCells={headCells}
              rows={rows}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

export default MatchedInvoiceModal;
