import { useMemo, useState } from "react";

import DatePicker from "@sellernote/_shared/src/admin-ui/components/DatePicker";
import useRadioGroupFilter, {
  RadioGroupFilterOptionList,
} from "@sellernote/_shared/src/hooks/admin/useRadioGroupFilter";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared/src/hooks/admin/useSearchWithTerm";
import { BusinessManagementType } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { toFormattedDateToLocaleDate } from "@sellernote/_shared/src/utils/common/date";

export const radioGroupOptionList: RadioGroupFilterOptionList<BusinessManagementType>[] =
  [
    {
      label: "DB 영업관리",
      value: "DBManagement",
    },
    {
      label: "견적제출 요망",
      value: "beforeEstimate",
    },
    {
      label: "사후관리",
      value: "waiting",
    },
    {
      label: "재영업 목록",
      value: "remarketing",
    },
  ];

const termSearchTypeOptionList: TermSearchType<"company" | "bidId">[] = [
  {
    label: "회사명",
    value: "company",
  },
  {
    label: "의뢰번호",
    value: "bidId",
  },
];

export default function useBusinessManagementFilter() {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [endDate, setEndDate] = useState<string | null>(
    toFormattedDateToLocaleDate({
      value: new Date(),
      timeZone: "Asia/Seoul",
    })
  );

  const handlePageReset = () => {
    setPage(0);
  };

  const { RadioGroupFilterPanel, radioGroupFilter } = useRadioGroupFilter({
    radioGroupFilterOptionList: radioGroupOptionList,
    afterRadioChangeCallback: handlePageReset,
    defaultValue: "beforeEstimate",
  });

  const { TermSearchPanel, debouncedSearchTermWithObject } = useSearchWithTerm({
    termSearchTypeOptions: termSearchTypeOptionList,
  });

  const EndDateFilter = useMemo(() => {
    return (
      <DatePicker
        when="end"
        value={endDate}
        setDate={(v) => {
          setEndDate(v);
        }}
      />
    );
  }, [endDate]);

  return {
    RadioGroupFilterPanel,
    radioGroupFilter,
    TermSearchPanel,
    debouncedSearchTermWithObject,
    EndDateFilter,
    endDate,
    page,
    setPage,
    perPage,
    setPerPage,
  };
}
