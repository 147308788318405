import React, { useCallback } from "react";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import FileName from "@sellernote/_shared/src/admin-ui/containers/FileName";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "@sellernote/_shared/src/constants/forwarding/adminZIndexLevelRecord";
import ADMIN_PROMOTION_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";

import FileUpload from "./PromotionFileUpload";

const PromotionFileUploadModal = ({
  setShowsUploadModal,
  showsUploadModal,
  listId,
}: {
  setShowsUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  showsUploadModal: boolean;
  listId: number;
}) => {
  const { data: detailData } =
    ADMIN_PROMOTION_QUERY.useGetPromotionDetail(listId);

  const handleModalClose = useCallback(() => {
    setShowsUploadModal(false);
  }, [setShowsUploadModal]);

  if (!detailData) {
    return <Loading active={true} />;
  }

  return (
    <Modal
      sx={{ zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.fileUploadModal }}
      handleClose={handleModalClose}
      isOpened={showsUploadModal}
      modalBody={
        <>
          <FileUpload detailData={detailData} />

          {detailData.attachments &&
            detailData.attachments.map((v) => {
              return (
                <FileName key={v.id} attachment={v} dataType="promotion" />
              );
            })}
        </>
      }
    />
  );
};

export default PromotionFileUploadModal;
