import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useAtom } from "jotai";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "jotaiStates/partnerManagement";

import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";

const FREIGHT_TYPE = ["FCL", "LCL", "AIR", "EXPRESS"];

export default function PartnerFreightType() {
  const [params, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const { transportMode } = params;

  const setFreightTypeUpdate = (val: AdminBidTransportMode[]) => {
    if (!val.length) return;

    setParams({ ...params, page: 0, transportMode: val });
  };

  return (
    <>
      <FormControl>
        <InputLabel>운송 유형</InputLabel>

        <Select
          autoWidth
          size="small"
          multiple
          label={"운송 유형"}
          value={transportMode}
          onChange={(e) => {
            setFreightTypeUpdate(e.target.value as AdminBidTransportMode[]);
          }}
          input={<OutlinedInput label="운송 유형" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selected.map((freight) => (
                <Chip key={freight} label={freight} size="small" />
              ))}
            </Box>
          )}
          MenuProps={{ PaperProps: { style: { minWidth: 150 } } }}
        >
          {FREIGHT_TYPE.map((freight) => (
            <MenuItem key={freight} value={freight}>
              <Checkbox
                checked={transportMode?.includes(
                  freight as AdminBidTransportMode
                )}
              />

              <ListItemText primary={freight} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box borderLeft={"1px solid #dddddd"} />
    </>
  );
}
