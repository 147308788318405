import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/LegacyTable";
import DetailModal from "@sellernote/_shared/src/admin-ui/pageContainers/trello/shipda/GeneralAndConsolidation/DetailModal";
import {
  GET_ADMIN_SETTLEMENT_LIST_REQ,
  GET_ADMIN_SETTLEMENT_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { SettlementListItem } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { changeBidProjectStatusNameToKr } from "@sellernote/_shared/src/utils/forwarding/bid";

type CellId = keyof SettlementListItem | "BL";

function SettlementTable({
  settlementListData,
  adminUserList,
  fetchSettlementListParams,
  setFetchSettlementListParams,
}: {
  settlementListData: GET_ADMIN_SETTLEMENT_LIST_RES | undefined;
  adminUserList: ForwardingAdminUserListItem[] | undefined;
  fetchSettlementListParams: Omit<
    GET_ADMIN_SETTLEMENT_LIST_REQ,
    "isSettlement" | "status" | "serviceType"
  >;
  setFetchSettlementListParams: (
    value: Omit<
      GET_ADMIN_SETTLEMENT_LIST_REQ,
      "isSettlement" | "status" | "serviceType"
    >
  ) => void;
}) {
  const trelloCardId = useRecoilValue(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [showsTrelloDetailModal, setShowsTrelloDetailModal] = useState(false);
  const [bidId, setBidId] = useState<number | undefined>();

  const handleDetailModalOpen = useCallback(
    (cardId: number | string | null) => {
      if (!cardId) return;
      setBidId(Number(cardId));
      setShowsTrelloDetailModal(true);
    },
    []
  );

  useEffect(() => {
    if (trelloCardId) {
      handleDetailModalOpen(trelloCardId);
    }
  }, [handleDetailModalOpen, trelloCardId]);

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
        width: 100,
      },
      {
        id: "BL",
        disablePadding: false,
        label: "BL",
        width: 100,
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      {
        id: "teamName",
        disablePadding: false,
        label: "팀명",
        width: 100,
      },
      {
        id: "forwardingManagerName",
        disablePadding: false,
        label: "담당자",
        width: 100,
      },
      {
        id: "projectStatus",
        disablePadding: false,
        label: "비고",
        width: 100,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!settlementListData?.list) return [];

    return settlementListData.list.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        bidId: v.bidId,
        BL: v.hBL || v.mBL,
        companyName: v.companyName,
        teamName: v.teamName,
        forwardingManagerName: v.forwardingManagerName,
        projectStatus: changeBidProjectStatusNameToKr(v.projectStatus),
        handleRowClick() {
          handleDetailModalOpen(v.bidId);
        },
      };

      return row;
    });
  }, [handleDetailModalOpen, settlementListData]);

  const handlePerPageChange = useCallback(
    (perPage: number) => {
      setFetchSettlementListParams({ ...fetchSettlementListParams, perPage });
    },
    [fetchSettlementListParams, setFetchSettlementListParams]
  );

  const handleCurrentPageChange = useCallback(
    (page: number) => {
      setFetchSettlementListParams({ ...fetchSettlementListParams, page });
    },
    [fetchSettlementListParams, setFetchSettlementListParams]
  );

  return (
    <Box sx={{ p: 1, background: "#fff" }}>
      <LegacyTable
        toolbarItems={{
          left: [
            <Typography key="total">
              총 {settlementListData?.total || 0}건
            </Typography>,
          ],
        }}
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: settlementListData?.total || 0,
          perPage: fetchSettlementListParams.perPage,
          setPerPage: handlePerPageChange,
          currentPage: fetchSettlementListParams.page,
          setCurrentPage: handleCurrentPageChange,
        }}
      />

      {showsTrelloDetailModal && (
        <DetailModal
          showsTrelloDetailModal={showsTrelloDetailModal}
          setShowsTrelloDetailModal={setShowsTrelloDetailModal}
          bidId={bidId}
        />
      )}
    </Box>
  );
}

export default SettlementTable;
