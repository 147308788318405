import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import { ISSUE_AUTO_INVOICE_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";

import { useSalesSettlementContext } from "pages/settlement/salesManagement/useSalesSettlementContext";

import { COLUMN_LIST } from "./constants";
import InvoiceRow from "./InvoiceRow";

function AutoInvoiceIssueModal({
  opensAutoInvoiceIssueModal,
  setOpensAutoInvoiceIssueModal,
  setIssueInvoiceModalVisible,
  handleAutoInvoiceIssue,
  refetchAdminSettlementSalesList,
  issueAutoInvoiceLoading,
}: {
  opensAutoInvoiceIssueModal: boolean;
  setOpensAutoInvoiceIssueModal: Dispatch<SetStateAction<boolean>>;
  setIssueInvoiceModalVisible: (value: boolean) => void;
  handleAutoInvoiceIssue: ({
    invoiceId,
    writeDate,
    invoiceeEmail,
    remark,
  }: ISSUE_AUTO_INVOICE_REQ) => void;
  refetchAdminSettlementSalesList: () => void;
  issueAutoInvoiceLoading: boolean;
}) {
  const history = useHistory();

  const { filteredSettlementList } = useSalesSettlementContext();

  const handleModalClose = () => {
    setOpensAutoInvoiceIssueModal(false);
    setIssueInvoiceModalVisible(false);
    refetchAdminSettlementSalesList();
  };

  return (
    <>
      <Modal
        isOpened={opensAutoInvoiceIssueModal}
        handleClose={handleModalClose}
        modalBody={
          <>
            {filteredSettlementList && (
              <>
                <Typography variant="h6">계산서 자동발행</Typography>

                <Divider sx={{ m: 1 }}>
                  <Typography variant="caption">
                    사용자 정보의 세금계산서 이메일은 변경되지 않습니다. 변경이
                    필요하면,{" "}
                    <Button
                      variant="text"
                      size="small"
                      sx={{ p: 0, fontSize: "0.75rem" }}
                      onClick={() => history.push("/users/list")}
                    >
                      사용자 정보
                    </Button>
                    에서 수정해 주세요.
                  </Typography>
                </Divider>

                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {COLUMN_LIST.map((column) => (
                          <TableCell
                            style={{
                              minWidth: column.width,
                              background: "#1f6ed5",
                              color: "white",
                            }}
                            key={column.id}
                          >
                            {column.id}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredSettlementList.map((invoice) => (
                        <InvoiceRow
                          key={invoice.id}
                          invoice={invoice}
                          isIssuing={issueAutoInvoiceLoading}
                          handleAutoInvoiceIssue={handleAutoInvoiceIssue}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        }
      />
    </>
  );
}

export default AutoInvoiceIssueModal;
