import { LegacyTableHeadCell } from "@sellernote/_shared/src/admin-ui/components/LegacyTable";
import { AdminUserGuideList } from "@sellernote/_shared/src/types/forwarding/adminBidUser";

const HEAD_CELL_LIST: LegacyTableHeadCell<
  keyof AdminUserGuideList | "domain"
>[] = [
  {
    id: "id",
    disablePadding: false,
    width: 80,
    label: "번호",
  },
  {
    id: "company",
    disablePadding: false,
    label: "회사명",
  },
  {
    id: "name",
    disablePadding: false,
    label: "담당자 이름",
  },
  {
    id: "phone",
    disablePadding: false,
    label: "연락처",
  },
  {
    id: "email",
    disablePadding: false,
    label: "이메일",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "신청일자",
    width: 230,
  },
  {
    id: "domain",
    disablePadding: false,
    label: "사업부문",
  },
  {
    id: "careType",
    disablePadding: false,
    label: "종류",
  },
  {
    id: "isGuideDownloaded",
    disablePadding: false,
    label: "다운로드",
  },
  {
    id: "userComment",
    disablePadding: false,
    label: "문의사항",
  },
];

export default HEAD_CELL_LIST;
