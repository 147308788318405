import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";

import useSessionInvoiceId from "../../../../hooks/useSessionInvoiceId";

import DeleteInvoiceModal from "./DeleteInvoiceModal";

function DeleteButton({
  invoiceData,
}: {
  invoiceData: InvoiceData | undefined;
}) {
  const { invoiceType } = useParams<{
    invoiceType: InvoiceType;
  }>();

  const [showsDeleteInvoiceModal, setShowsDeleteInvoiceModal] = useState(false);

  const { sessionInvoiceId } = useSessionInvoiceId();

  const hasDeleteButton = (() => {
    return invoiceType === "warehouseReceipt" || invoiceType === "etcDeposit";
  })();

  const { handleSnackbarOpen } = useSnackbar();

  const handleDeleteInvoiceModalOpen = () => {
    if (!sessionInvoiceId) {
      handleSnackbarOpen("명세서가 존재하지 않습니다.", "error");
      return;
    }

    if (invoiceData?.isSended) {
      handleSnackbarOpen("이미 발송된 명세서는 삭제할 수 없습니다.", "error");
      return;
    }
    setShowsDeleteInvoiceModal(true);
  };

  const handleDeleteInvoiceModalClose = () => {
    setShowsDeleteInvoiceModal(false);
  };

  return (
    <>
      {hasDeleteButton && (
        <Button
          onClick={handleDeleteInvoiceModalOpen}
          variant="contained"
          color="error"
        >
          삭제
        </Button>
      )}

      {showsDeleteInvoiceModal && sessionInvoiceId && (
        <DeleteInvoiceModal
          handleDeleteInvoiceModalClose={handleDeleteInvoiceModalClose}
          showsDeleteInvoiceModal={showsDeleteInvoiceModal}
          invoiceId={Number(sessionInvoiceId)}
        />
      )}
    </>
  );
}

export default DeleteButton;
