import { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";

import { SelectedShipment } from "../types";

const HAS_EXISTING_PO_NUMBER_WARNING_MESSAGE = (
  <Typography className="sads">
    <b>이미 PO번호가 존재하는 운송의뢰가 포함되어 있습니다.</b>
    <br />
    <br />
    계속 진행하면 선택하신 운송의뢰 중 일부는 PO번호가 여러개 매핑될 수
    있습니다.
    <br />
    <br />
    계속 진행하시겠습니까?
  </Typography>
);

export default function MappingButton({
  selectedList,
  setSelectedList,
  onModalClose,
}: {
  selectedList: SelectedShipment[];
  setSelectedList: (list: SelectedShipment[]) => void;
  onModalClose: () => void;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id: poId } = useParams<{ id: string }>();

  const [opensWarningModal, setOpensWarningModal] = useState(false);

  const orderDetailQuery = ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
    id: Number(poId),
  });

  const {
    mutate: mapShipment,
    ResponseHandler: ResponseHandlerOfMappingShipment,
  } = ADMIN_ORDER_QUERY.useMappingShipment({
    poId: Number(poId),
    onSuccess: () => {
      showSnackbar("매핑에 성공하셨습니다.");
      setSelectedList([]);
      onModalClose();

      /** 발주 상세 > 운송정보 데이터 갱신 */
      return queryClient.invalidateQueries(orderDetailQuery);
    },
  });

  const shipmentIdList = selectedList.map(({ shipmentId }) => shipmentId);

  /** 선택된 운송의뢰 중 이미 PO번호가 매핑된 운송의뢰가 있는지 여부 */
  const hasMappedPOShipment = selectedList.some(({ isMapped }) => isMapped);

  return (
    <>
      <Button
        variant="contained"
        size="large"
        className="sads"
        sx={{ width: 200, alignSelf: "flex-end" }}
        disabled={selectedList.length === 0}
        onClick={() => {
          if (hasMappedPOShipment) {
            setOpensWarningModal(true);
            return;
          }

          mapShipment({ shipmentIds: shipmentIdList });
        }}
      >
        매핑하기
      </Button>

      <Modal
        isOpened={opensWarningModal}
        handleClose={() => setOpensWarningModal(false)}
        modalBody={
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {HAS_EXISTING_PO_NUMBER_WARNING_MESSAGE}

            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                className="sads"
                variant="outlined"
                color="error"
                onClick={() => setOpensWarningModal(false)}
                fullWidth
              >
                취소
              </Button>

              <Button
                variant="contained"
                className="sads"
                onClick={() => {
                  mapShipment({ shipmentIds: shipmentIdList });
                }}
                fullWidth
              >
                진행
              </Button>
            </Box>
          </Box>
        }
      />

      {ResponseHandlerOfMappingShipment}
    </>
  );
}
