import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

function DeleteSalesListModal({
  deleteSalesListModalModalVisible,
  setDeleteSalesListModalModalVisible,
  refetchAdminSettlementSalesList,
  deleteSalesListId,
  setDeleteSalesListId,
}: {
  deleteSalesListModalModalVisible: boolean;
  setDeleteSalesListModalModalVisible: Dispatch<SetStateAction<boolean>>;
  refetchAdminSettlementSalesList: () => void;
  deleteSalesListId: number;
  setDeleteSalesListId: Dispatch<SetStateAction<number>>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: deleteSalesList } = ADMIN_SETTLEMENT_QUERY.useDeleteInvoice({
    id: deleteSalesListId,

    onSuccess: () => {
      handleSnackbarOpen("리스트를 성공적으로 삭제했습니다.");
      setDeleteSalesListId(0);
      setDeleteSalesListModalModalVisible(false);
      refetchAdminSettlementSalesList();
    },

    onError: (error) => {
      if (error?.errorCode === "E081") {
        handleSnackbarOpen(
          "계산서가 발행된 경우는 관리자에게 요청해주세요.",
          "error"
        );
        return;
      }

      handleSnackbarOpen("요청에 실패했습니다.", "error");
      setDeleteSalesListModalModalVisible(false);
    },
  });

  const handleModalClose = useCallback(() => {
    setDeleteSalesListModalModalVisible(false);
    setDeleteSalesListId(0);
  }, [setDeleteSalesListId, setDeleteSalesListModalModalVisible]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          리스트를 삭제하시겠습니까?
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: 1, maxWidth: 400 }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => deleteSalesList(deleteSalesListId)}
            >
              예
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              onClick={handleModalClose}
            >
              아니오
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [deleteSalesList, deleteSalesListId, handleModalClose]);

  return (
    <Modal
      isOpened={deleteSalesListModalModalVisible}
      handleClose={handleModalClose}
      modalBody={ModalBody}
    />
  );
}

export default DeleteSalesListModal;
