import React, { useMemo, useState } from "react";
import { Box, Card, Typography } from "@mui/material";

import Table from "@sellernote/_shared/src/admin-ui/components/Table";
import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { Zone } from "@sellernote/_shared/src/types/common/common";

const COLUMNS = [
  {
    id: "id",
    disablePadding: false,
    label: "ID",
  },
  {
    id: "address",
    disablePadding: false,
    label: "장소",
  },
];

const ZoneList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { data: zoneList } = ADMIN_COMMON_QUERY.useGetZoneList();

  const rowList = useMemo(() => {
    if (!zoneList) return [];

    return zoneList
      .filter((zone: Zone) => zone.name.includes(searchText))
      .map((zone) => ({
        id: zone.id,
        address: zone.name,
      }))
      .filter((_, i) => {
        const startIndex = currentPage * 25;
        const endIndex = startIndex + 25;
        return i >= startIndex && i < endIndex;
      });
  }, [currentPage, searchText, zoneList]);

  const totalLength = useMemo(() => {
    if (!zoneList) return 0;

    return zoneList.filter((zone: Zone) => zone.name.includes(searchText))
      .length;
  }, [searchText, zoneList]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Layout breadcrumbs={["장소 관리"]} title={"장소 관리"}>
      <Card>
        <Box
          display={"flex"}
          p={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>{totalLength}개</Typography>

          <TextField
            size="small"
            onChange={handleSearch}
            sx={{ width: 300 }}
            label="장소"
          />
        </Box>

        <Table
          headCells={COLUMNS}
          rows={rowList}
          pagination={{
            rowsPerPageOptions: [],
            totalCount: totalLength,
            currentPage,
            perPage: 25,
            setCurrentPage,
            setPerPage: () => {},
          }}
        />
      </Card>
    </Layout>
  );
};

export default ZoneList;
