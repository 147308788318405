import { useParams } from "react-router-dom";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_PROMOTION_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";

import DetailDescription from "./DetailDescription";

const PromotionDetail = () => {
  const { id }: { id: string } = useParams();

  const { data: detailData } = ADMIN_PROMOTION_QUERY.useGetPromotionDetail(id);

  if (!detailData) {
    return (
      <Layout>
        <Loading active={true}></Loading>
      </Layout>
    );
  }

  return (
    <Layout>
      <DetailDescription detailData={detailData} />
    </Layout>
  );
};

export default PromotionDetail;
