import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import useForwardingManagerAdminUser from "@sellernote/_shared/src/hooks/admin/useForwardingManagerAdminUser";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

import PromotionTable from "./PromotionTable";

const Promotion = () => {
  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { forwardingManagerAdminId, forwardingManagerAdminList } =
    useForwardingManagerAdminUser({
      adminUserList,
    });

  // TODO: 초기 진입시 담당자필터 기본값을 셋팅하기 위해 forwardingManagerAdminId값의 여부를 로딩으로 사용 개선이 필요
  if (!adminUserList || !forwardingManagerAdminId) {
    return (
      <Layout>
        <Loading active={true}></Loading>
      </Layout>
    );
  }

  return (
    <Layout title="프로모션 관리">
      <PromotionTable
        adminData={adminUserList}
        adminUserId={forwardingManagerAdminId}
        forwardingManagerAdminList={forwardingManagerAdminList}
      />
    </Layout>
  );
};

export default Promotion;
