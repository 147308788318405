import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import DatePicker from "@sellernote/_shared/src/admin-ui/components/DatePicker";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/Table";
import { ADMIN_BUSINESS_MANAGEMENT_NO_PROCEED_REASON_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBusinessManagement";
import useYesOrNoModal from "@sellernote/_shared/src/hooks/admin/useYesOrNoModal";
import BUSINESS_MANAGEMENT_QUERY, {
  BUSINESS_MANAGEMENT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";
import { PagingResponseForBoard } from "@sellernote/_shared/src/types/common/common";
import { BusinessManagementListItem } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  changeBusinessManagementServiceTypeToNameKr,
  changeBusinessManagementStatusToNameKr,
} from "@sellernote/_shared/src/utils/forwarding/admin/businessManagement";

import CommentModal from "./CommentModal";

type CellId = keyof BusinessManagementListItem | "applyBid";

const WaitingTable = ({
  perPage,
  setPerPage,
  setPage,
  page,
  businessManagementList,
}: {
  perPage: number;
  setPerPage: (value: number) => void;
  setPage: (value: number) => void;
  page: number;
  businessManagementList:
    | PagingResponseForBoard<BusinessManagementListItem>
    | undefined;
}) => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const [showsCommentModal, setShowsCommentModal] = useState(false);
  const [bidDetailId, setBidDetailId] = useState(0);
  const [remarketingDate, setRemarketingDate] = useState<string | null>(null);
  const [
    showsRemarketingDateConfirmModal,
    setShowsRemarketingDateConfirmModal,
  ] = useState(false);

  const {
    mutate: updateNoProceedReason,
    ResponseHandler: ResponseHandlerOfUpdateNoProceedReason,
  } = BUSINESS_MANAGEMENT_QUERY.useUpdateNoProceedReason({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        queryClient.invalidateQueries(BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: "미수주 사유를 등록했습니다.",
      }),
    },
  });

  const {
    mutate: updateRemarketingDate,
    ResponseHandler: ResponseHandlerOfUpdateRemarketingDate,
  } = BUSINESS_MANAGEMENT_QUERY.useUpdateRemarketingDate({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setRemarketingDate(null);
        setShowsRemarketingDateConfirmModal(false);
        queryClient.invalidateQueries(BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: "재영업일자를 등록했습니다.",
      }),
    },
  });

  const { yesOrNoModal } = useYesOrNoModal({
    title: "다른 의뢰에도 같은 사유를 등록하시겠습니까?",
    onOk: () =>
      updateRemarketingDate({
        bidId: bidDetailId,
        // TODO: toFormattedDate > payload 형식이 지정되어 있어서 수정 보류
        remarketingDate: toFormattedDate(remarketingDate, "YYYY-MM-DD"),
        remarketingFlag: false,
        groupFlag: true,
      }),
    onCancel: () =>
      updateRemarketingDate({
        bidId: bidDetailId,
        // TODO: toFormattedDate > payload 형식이 지정되어 있어서 수정 보류
        remarketingDate: toFormattedDate(remarketingDate, "YYYY-MM-DD"),
        remarketingFlag: false,
      }),
    showsYesOrNoModal: showsRemarketingDateConfirmModal,
    setShowsYesOrNoModal: setShowsRemarketingDateConfirmModal,
  });

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "managerName",
        disablePadding: false,
        label: "담당자",
        width: 100,
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
        width: 120,
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "견적생성일",
        width: 150,
      },
      {
        id: "status",
        disablePadding: false,
        label: "현재 상태",
        width: 150,
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "팀명(회사명)",
        width: 200,
      },
      {
        id: "serviceType",
        disablePadding: false,
        label: "운송유형",
        width: 100,
      },
      {
        id: "userName",
        disablePadding: false,
        label: "성함",
        width: 100,
      },
      {
        id: "userPhone",
        disablePadding: false,
        label: "연락처",
        width: 150,
      },
      {
        id: "route",
        disablePadding: false,
        label: "구간",
        width: 200,
      },
      {
        id: "noProceedReason",
        disablePadding: false,
        label: "미수주 사유",
        width: 150,
      },

      {
        id: "comments",
        disablePadding: false,
        label: "비고",
        width: 80,
      },
      {
        id: "remarketingDate",
        disablePadding: false,
        label: "재영업일 일정",
        width: 150,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!businessManagementList?.list) return [];

    return businessManagementList?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        managerName: v.managerName,
        bidId: (
          <Button
            onClick={() => {
              history.push(`/bid/detail/${v.bidId}`);
            }}
          >
            {v.bidId}
          </Button>
        ),
        createdAt: v.createdAt,
        status: changeBusinessManagementStatusToNameKr(
          v.status,
          v.projectStatus
        ),
        companyName: `${v.teamName ?? ""}${
          v.companyName ? `(${v.companyName})` : ""
        }`,
        serviceType: changeBusinessManagementServiceTypeToNameKr(v.serviceType),
        userName: v.userName,
        userPhone: v.userPhone,
        route: v.route,
        noProceedReason: (
          <FormControl variant="standard" fullWidth={true}>
            <Select
              value={v.noProceedReason}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                updateNoProceedReason({
                  bidId: v.bidId,
                  noProceedReason: e.target.value,
                });
              }}
            >
              {ADMIN_BUSINESS_MANAGEMENT_NO_PROCEED_REASON_OPTION_LIST.map(
                (v) => {
                  return (
                    <MenuItem key={v.value} value={v.value}>
                      {v.label}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        ),
        comments: (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setBidDetailId(v.bidId);
              setShowsCommentModal(true);
            }}
          >
            {"확인"}
          </Button>
        ),
        remarketingDate: (
          <DatePicker
            value={null}
            setDate={(date: string | null) => {
              setBidDetailId(v.bidId);
              setRemarketingDate(date);
              setShowsRemarketingDateConfirmModal(true);
            }}
            when="start"
            isDisablePast={true}
          />
        ),
      };

      return row;
    });
  }, [businessManagementList?.list, history, updateNoProceedReason]);

  return (
    <div>
      <Table
        toolbarItems={{
          left: [
            <Typography key="total">
              총 {businessManagementList?.total || 0}건
            </Typography>,
          ],
        }}
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: businessManagementList?.total || 0,
          perPage,
          setPerPage,
          currentPage: page,
          setCurrentPage: setPage,
        }}
      />

      {showsCommentModal && (
        <CommentModal
          setShowsCommentModal={setShowsCommentModal}
          showsCommentModal={showsCommentModal}
          bidDetailId={bidDetailId}
        />
      )}

      {yesOrNoModal}

      {ResponseHandlerOfUpdateNoProceedReason}

      {ResponseHandlerOfUpdateRemarketingDate}
    </div>
  );
};

export default WaitingTable;
