import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Box, Button, Switch } from "@mui/material";
import { useAtom } from "jotai";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "jotaiStates/partnerManagement";

import Table from "@sellernote/_shared/src/admin-ui/components/Table";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import { ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PARTNER_MANAGEMENT_QUERY";
import CUSTOMS_PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_PARTNER_QUERY";
import { PartnerListInfo } from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

import useDeactivatePartner from "./hooks/useDeactivatePartner";
import useHandleDeletePartner from "./hooks/useHandleDeletePartner";
import {
  getHeadCellList,
  renderActivateCheckbox,
  renderBank,
  renderBankAccount,
  renderBRN,
  renderBRNFile,
  renderIsInvoiceIssued,
  renderPaymentCondition,
  renderTransactionFlag,
} from "./utils";

import PARTNER_TYPE from "../FilterGroup/PartnerBusinessArea/constants";
import ChipGroup from "./ChipGroup";

export default function PartnerTable({
  partnerList,
  total,
}: {
  partnerList: PartnerListInfo[] | undefined;
  total: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.get("businessArea") || undefined;

  /** 쉽다 관세사, 화주 관세사일 때 관세사 어드민 사용 여부 관리 Switch 노출 */
  const showsCustomAdminToggle =
    queryString === "shipDaCustoms" || queryString === "customs";

  const [params, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const { handlePartnerDelete } = useHandleDeletePartner();
  const { handlePartnerDeactivate } = useDeactivatePartner();

  const {
    mutate: activateCustom,
    ResponseHandler: ResponseHandlerOfChangeCustomsActivateFlag,
  } = CUSTOMS_PARTNER_QUERY.useChangeCustomsActivateFlag();

  const handleCustomsActivateFlagChange = (
    activateFlag: boolean,
    managerId: number
  ) => {
    activateCustom(
      { activateFlag, pathParams: { managerId } },
      {
        onSuccess: () => {
          handleSnackbarOpen("관세사 어드민 사용 여부가 변경되었습니다.");

          queryClient.invalidateQueries(
            ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList({
              page: params.page,
              perPage: params.perPage,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen(
            "관세사 어드민 사용 유저를 찾을 수 없습니다.",
            "error"
          );
        },
      }
    );
  };

  const row = (() => {
    if (!partnerList?.length) return [];

    const rowList = partnerList?.map((partner) => {
      const { id, businessArea, name, language, invoiceIssueFlag, managers } =
        partner;

      const mainManager = managers.find((manager) => manager.isMain);

      return {
        backgroundColor: partner.deactivatedAt ? "#eeeeee" : "inherit",
        /** 파트너 상세로 이동 */
        handleRowClick: () => history.push(`/partner-management/${id}`),
        /** 파트너 정보 */
        id,
        ...(showsCustomAdminToggle
          ? {
              어드민: (
                <Switch
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    handleCustomsActivateFlagChange(
                      e.target.checked,
                      mainManager?.id ?? 0
                    );
                  }}
                  checked={mainManager?.isCustomsAdmin}
                />
              ),
            }
          : {}),
        유형:
          PARTNER_TYPE.find(({ value }) => value === businessArea)?.label ??
          businessArea,
        회사명: name,
        "수출입 구분": (
          <ChipGroup list={partner.shipmentTypes} isShipmentType={true} />
        ),
        언어: language === "kr" ? "한국어" : "영어",
        /** 파트너 담당자 정보 */
        "담당 국가": <ChipGroup list={partner.countries} />,
        "운송 유형": <ChipGroup list={partner.transportModes} />,
        "담당자 이름": managers[0].name,
        직함: replaceEmptyToDash(managers[0].position),
        전화번호: replaceEmptyToDash(managers[0].phone),
        이메일: managers[0].email,
        /** 정산 정보 */
        "정산 유형": renderPaymentCondition(partner.financialAccounts),
        "금전거래 여부": renderTransactionFlag(partner.isFinancial),
        "사업자 등록번호": renderBRN(partner.financialAccounts),
        "사업자 등록증": renderBRNFile(partner.financialAccounts),
        은행: renderBank(partner.financialAccounts),
        계좌번호: renderBankAccount(partner.financialAccounts),
        "계산서 발행": renderIsInvoiceIssued(invoiceIssueFlag),

        활성화: renderActivateCheckbox({
          isDeactivated: Boolean(partner.deactivatedAt),
          onPartnerDeactivate: handlePartnerDeactivate,
          partnerId: partner.id,
        }),

        삭제: (
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 0, minWidth: 50 }}
            onClick={(e) => {
              e.stopPropagation();
              handlePartnerDelete({ id });
            }}
          >
            삭제
          </Button>
        ),
      };
    });

    return rowList;
  })();

  return (
    <Box>
      <Table
        showsTableVerticalLine
        sx={{ maxHeight: 700 }}
        headCells={getHeadCellList(showsCustomAdminToggle)}
        rows={row}
        pagination={{
          rowsPerPageOptions: [10, 25, 50],
          totalCount: total,
          perPage: params.perPage,
          setPerPage: (perPage) => setParams({ ...params, perPage }),
          currentPage: params.page,
          setCurrentPage: (page) => setParams({ ...params, page }),
        }}
      />

      {ResponseHandlerOfChangeCustomsActivateFlag}
    </Box>
  );
}
