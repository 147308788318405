import { useState } from "react";
import { Box, Button } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";

export default function UserComment({ comment }: { comment: string }) {
  const [opensUserCommentModal, setOpensUserCommentModal] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpensUserCommentModal(true)}
      >
        보기
      </Button>

      <Modal
        isOpened={opensUserCommentModal}
        handleClose={() => setOpensUserCommentModal(false)}
        modalBody={
          <Box
            sx={{
              width: 400,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {comment}

            <Button
              variant="contained"
              size="medium"
              onClick={() => setOpensUserCommentModal(false)}
              sx={{ marginTop: "20px" }}
            >
              확인
            </Button>
          </Box>
        }
      />
    </>
  );
}
