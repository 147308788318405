import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";

import TeamTable from "../TeamTable";

function TeamList() {
  return (
    <Layout title="팀 관리">
      <TeamTable />
    </Layout>
  );
}

export default TeamList;
