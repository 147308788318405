import { useCallback, useMemo, useState } from "react";
import { Modal } from "antd";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";

export default function useEmailBrochures(title: string, html: string) {
  const { handleSnackbarOpen } = useSnackbar();

  const [isVisiblePreview, setIsVisiblePreview] = useState(false);

  const showPreview = useCallback(() => {
    setIsVisiblePreview(true);
  }, []);

  const copyCode = useCallback(() => {
    navigator.clipboard
      .writeText(html)
      .then(() => handleSnackbarOpen("복사되었습니다"))
      .catch(() => handleSnackbarOpen("복사도중 오류가 발생했습니다", "error"));
  }, [handleSnackbarOpen, html]);

  const Preview = useMemo(() => {
    if (!isVisiblePreview) return null;

    return (
      <Modal
        title={title}
        visible
        cancelText="취소"
        onCancel={() => setIsVisiblePreview(false)}
        okText="코드 복사"
        onOk={() => copyCode()}
        width={1000}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      </Modal>
    );
  }, [title, isVisiblePreview, html, copyCode]);

  return { showPreview, Preview, copyCode };
}
