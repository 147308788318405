import { useMemo, useState } from "react";
import { Button } from "@mui/material";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/LegacyTable";
import FileName from "@sellernote/_shared/src/admin-ui/containers/FileName";
import UploadModal from "@sellernote/_shared/src/admin-ui/containers/UploadModal";
import {
  AdminOrderDetail,
  POAttachmentProps,
} from "@sellernote/_shared/src/types/forwarding/adminOrder";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

export default function OrderUploadDocumentTable(
  orderDetail: AdminOrderDetail
) {
  type CellId = keyof POAttachmentProps | "numbering" | "upload";

  const [showsUploadModal, setShowsUploadModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(20);

  const handleUploadModalOpen = () => {
    setShowsUploadModal(true);
  };

  const rows = useMemo(() => {
    if (!orderDetail?.attachments) return [];

    return orderDetail?.attachments.map((v, index) => {
      const row: LegacyTableBodyRow<CellId> = {
        numbering: `${index + 1}번 서류`,
        name: (
          <FileName
            attachment={{
              name: v.name,
              isAdmin: v.isAdmin === 0 ? true : false,
              author: v.author,
              createdAt: v.createdAt,
              key: v.key,
              authority: "Admin",
              id: v.id || 0,
              bucket: "",
            }}
            dataType={"order"}
          />
        ),
        author: v.author,
        createdAt: formatDate({
          date: v.createdAt,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
      };

      return row;
    });
  }, [orderDetail.attachments]);

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "numbering",
        disablePadding: false,
        label: "구분",
        width: 90,
      },
      {
        id: "name",
        disablePadding: false,
        label: "파일 이름",
        width: 90,
      },
      {
        id: "author",
        disablePadding: false,
        label: "파일 갱신 주체",
        width: 110,
      },

      {
        id: "createdAt",
        disablePadding: false,
        label: "처리 일시",
        width: 140,
      },
    ];
    return headCells;
  }, []);

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: orderDetail.attachments.length || 0,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
  };
  return (
    <>
      <LegacyTable
        title="서류 관리"
        headCells={HeadCells}
        rows={rows}
        pagination={pagination}
        toolbarItems={{
          right: [
            <Button
              variant="outlined"
              key="uploadButton"
              onClick={() => handleUploadModalOpen()}
            >
              업로드
            </Button>,
          ],
        }}
      />

      {showsUploadModal && (
        <UploadModal
          setShowsUploadModal={setShowsUploadModal}
          showsUploadModal={showsUploadModal}
          type="purchaseOrder"
          id={orderDetail.id}
          dataType="order"
          isPermission={false}
          teamId={orderDetail.teamId}
        />
      )}
    </>
  );
}
