const HEAD_CELL_LIST = [
  {
    id: "id",
    disablePadding: false,
    label: "운송의뢰번호",
  },
  {
    id: "userName",
    disablePadding: false,
    label: "담당자명",
    width: 100,
  },
  {
    id: "cargoInfo",
    disablePadding: false,
    label: "화물정보",
    width: 180,
  },
  {
    id: "shippingInfo",
    disablePadding: false,
    label: "운송정보",
  },
  {
    id: "checkpoint",
    disablePadding: false,
    label: "체크포인트",
    width: 180,
  },
  {
    id: "status",
    disablePadding: false,
    label: "상태",
  },
  {
    id: "projectStatus",
    disablePadding: false,
    label: "세부 상태",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "생성일",
  },
  {
    id: "remove",
    disablePadding: false,
    label: "연결 해제",
  },
];

export default HEAD_CELL_LIST;
