import { useMemo } from "react";
import { useAtomValue } from "jotai";
import { FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS } from "jotaiStates/tradingStatement";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { checkExchangeModification } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

/**
 * 거래명세서의 화물과 환율 정보가 변경됐는지 확인하는 값을 리턴한다.
 *  - 화물, 환율 정보 및 RequestInvoice > 개별 발송 버튼에서 validation으로 사용
 */
export default function useCompareWithDefaultValue({
  invoiceState,
  shipmentDetailData,
  directAirRton,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  directAirRton: number;
}) {
  const defaultExchangeRateList = useAtomValue(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_EXCHANGE_RATE_LIST
  );

  const defaultCbm = useAtomValue(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_CBM
  );

  const defaultTon = useAtomValue(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_TON
  );

  const defaultPackageType = useAtomValue(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_PACKAGE
  );

  const defaultDirectRton = useAtomValue(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_DIRECT_RTON
  );

  const isCargoInfoUnchanged = useMemo(() => {
    if (shipmentDetailData.freightType === "AIR") {
      return (
        directAirRton === defaultDirectRton &&
        invoiceState.ton === defaultTon &&
        invoiceState.packageValue === defaultPackageType
      );
    }

    // 기본 셋팅값과 변경된 값이 같다면 true를 리턴한다.
    return (
      invoiceState.cbm === defaultCbm &&
      invoiceState.ton === defaultTon &&
      invoiceState.packageValue === defaultPackageType
    );
  }, [
    defaultCbm,
    defaultDirectRton,
    defaultPackageType,
    defaultTon,
    directAirRton,
    invoiceState,
    shipmentDetailData.freightType,
  ]);

  const isExchangeInfoUnchanged = useMemo(() => {
    return (["USD", "EUR", "CNY", "GBP"] as Currency[]).every(
      (currency) =>
        checkExchangeModification({
          currency,
          defaultExchangeRateList,
          invoiceState,
        }) === false
    );
  }, [defaultExchangeRateList, invoiceState]);

  return {
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
  };
}
