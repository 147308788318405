import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import { getInvoiceTypeKR } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

function DeleteInvoiceModal({
  handleDeleteInvoiceModalClose,
  showsDeleteInvoiceModal,
  invoiceId,
}: {
  handleDeleteInvoiceModalClose: () => void;
  showsDeleteInvoiceModal: boolean;
  invoiceId: number;
}) {
  const { bidId, invoiceType } = useParams<{
    bidId: string;
    invoiceType: InvoiceType;
  }>();

  const {
    mutate: deleteInvoice,
    ResponseHandler: ResponseHandlerOfDeleteInvoice,
  } = ADMIN_FILE_QUERY.useDeleteInvoice(invoiceId);

  const handleInvoiceDelete = useCallback(() => {
    // TODO: snackbar 머지 후 작업 추가
    deleteInvoice(
      {},
      {
        onSuccess: () => {
          history.go(-1);
        },

        onError: () => {},
      }
    );
  }, [deleteInvoice]);

  return (
    <>
      <Modal
        handleClose={handleDeleteInvoiceModalClose}
        isOpened={showsDeleteInvoiceModal}
        modalBody={
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid item>
              <Typography
                variant="h6"
                component="span"
              >{`${bidId}의 ${getInvoiceTypeKR(invoiceType)}`}</Typography>

              <Typography variant="body1" component="span">
                을(를))
              </Typography>
            </Grid>

            <Grid item>
              <Typography>삭제하시겠습니까?</Typography>
            </Grid>

            <Grid item>
              <Button
                onClick={handleDeleteInvoiceModalClose}
                variant="text"
                sx={{ color: grey[900] }}
              >
                아니오
              </Button>
              /
              <Button
                onClick={handleInvoiceDelete}
                variant="text"
                color="primary"
              >
                제거
              </Button>
            </Grid>
          </Grid>
        }
      />

      {ResponseHandlerOfDeleteInvoice}
    </>
  );
}

export default DeleteInvoiceModal;
