import React from "react";
import { Button } from "@mui/material";

import { AccountDetailFile } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { FileRelatedToFinAccount } from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";

const renderFileButton = ({
  list,
  targetDomain,
  setPreviewFile,
}: {
  list: FileRelatedToFinAccount[];
  targetDomain: AccountDetailFile["domain"];
  setPreviewFile: (value: FileRelatedToFinAccount) => void;
}) => {
  if (!list.length) return "-";

  const targetFileList = list.filter(({ domain }) => domain === targetDomain);

  const fileButton = targetFileList.map((file, index) => (
    <React.Fragment key={file.key}>
      {index !== 0 && <br />}

      <Button onClick={() => setPreviewFile(file)} sx={{ p: 0 }}>
        {omitWithEllipsis({
          src: file.name,
          maxLength: 14,
          ellipsis: "...",
        })}
      </Button>
    </React.Fragment>
  ));

  return fileButton;
};

export { renderFileButton };
