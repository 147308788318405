import { useCallback, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { useRecoilState } from "recoil";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_ORDER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";

import { SHIPDA_ADMIN_FILTER_ATOMS } from "../../../states/filters";
import OrderManagementTable from "../OrderManagementTable";
import useOrderTablePanel from "../OrderManagementTable/useOrderTablePanel";
import useTableHeadPanel from "../useTableHeadPanel";

const boxLayoutStyle: SxProps<Theme> = { p: 1, background: "#fff" };

const OrderSub = () => {
  const [filterDataNotYet, setFilterDataNotYet] = useRecoilState(
    SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_ORDER_FILTER_LIST_NOT_YET
  );

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(20);

  const {
    debouncedSearchTermWithObject: debouncedSearchTermWithObjectNotYetTable,
    dateFilter: dateFilterNotYetTable,
    TablePanel: TablePanelNotYetTable,
  } = useOrderTablePanel("ADMIN_ORDER_FILTER_LIST_NOT_YET");

  const {
    NotYetTableHeadPanel,
    tableHeadFilterData: notYetHeadFilterData,
    handleFilterReset,
  } = useTableHeadPanel("ADMIN_ORDER_FILTER_LIST_NOT_YET");

  const saveFilterDataRefCallback = useCallback(() => {
    setFilterDataNotYet({
      ...debouncedSearchTermWithObjectNotYetTable,
      ...dateFilterNotYetTable,
      ...notYetHeadFilterData,
    });
  }, [
    notYetHeadFilterData,
    debouncedSearchTermWithObjectNotYetTable,
    dateFilterNotYetTable,
    setFilterDataNotYet,
  ]);

  const {
    data: orderList,
    isLoading: isLoadingForOrderList,
    refetch: refetchOrderList,
  } = ADMIN_ORDER_QUERY.useGetAdminOrderList({
    page: currentPage,
    perPage,
    ...filterDataNotYet,
  });

  return (
    <Layout title={"선적미계획 의뢰"}>
      <Box sx={boxLayoutStyle}>{TablePanelNotYetTable}</Box>

      <Box sx={boxLayoutStyle}>
        <OrderManagementTable
          orderList={orderList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
          TableHeadPanel={NotYetTableHeadPanel}
          saveFilterDataRefCallback={saveFilterDataRefCallback}
          isLoadingForOrderList={isLoadingForOrderList}
          refetchOrderList={refetchOrderList}
          handleFilterReset={handleFilterReset}
        />
      </Box>
    </Layout>
  );
};

export default OrderSub;
