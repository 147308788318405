import { useCallback, useMemo } from "react";
import { Box, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { SHIPDA_ADMIN_FILTER_ATOMS } from "states/filters";

import { GET_OPERATION_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/businessManagement";
import useSearchWithDateWithNoUseEffect from "@sellernote/_shared/src/hooks/admin/useSearchWithDateWithNoUseEffect";
import useSearchWithTermWithHistorySave, {
  TermSearchType,
} from "@sellernote/_shared/src/hooks/admin/useSearchWithTermWithHistorySave";
import useTemplateTableAutoCompletes from "@sellernote/_shared/src/hooks/admin/useTemplateTableAutoCompletes";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { getMonthFromToday } from "@sellernote/_shared/src/utils/common/date";

const useOperationTablePanel = ({
  key = "adminBidFilter",
}: {
  key?: string;
}) => {
  const [filterData, setFilterData] = useRecoilState(
    SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_OPERATION_FILTER_LIST
  );

  const termSearchTypeOptions: TermSearchType<
    keyof GET_OPERATION_LIST_FILTER
  >[] = useMemo(() => {
    return [
      {
        label: "파트너사",
        value: "partnerCompanyName",
      },
      {
        label: "회사명",
        value: "userCompanyName",
      },
      {
        label: "BID ID",
        value: "bidId",
      },
    ];
  }, []);

  const searchTermHistory = useMemo(() => {
    const termSearchTypeArray = termSearchTypeOptions.map((item) => item.value);

    const itemKey = termSearchTypeArray?.find(
      (item) => filterData[item] && item
    );
    const itemKeyIndex = termSearchTypeArray.findIndex(
      (item) => filterData[item] && filterData[item]
    );
    if (itemKey && itemKeyIndex)
      return {
        itemKey,
        itemKeyIndex,
      };
  }, [filterData, termSearchTypeOptions]);

  const historyDataCallback = useCallback(
    (itemKey: keyof GET_OPERATION_LIST_FILTER = "partnerCompanyName") => {
      return filterData[itemKey] && filterData[itemKey];
    },
    [filterData]
  );

  const { TermSearchPanel, debouncedSearchTermWithObject, reset } =
    useSearchWithTermWithHistorySave({
      termSearchTypeOptions,
      key,
      history: searchTermHistory,
      historyData: searchTermHistory
        ? historyDataCallback(searchTermHistory?.itemKey)
        : historyDataCallback(),
    });

  const ETDdateSearchTypeOptions = [
    {
      label: "ETD",
      value: "ETD",
    },
  ];

  const ETAdateSearchTypeOptions = [
    {
      label: "ETA",
      value: "ETA",
    },
    {
      label: "ATA",
      value: "ATA",
    },
  ];

  const {
    DateSearchPanel: ETDDateSearchPanel,
    startDate: etdStartDate,
    endDate: etdEndDate,
    setStartDate: setEtdStartDate,
    setEndDate: setEtdEndDate,
  } = useSearchWithDateWithNoUseEffect({
    dateSearchTypeOptions: ETDdateSearchTypeOptions,
    key,
    dateHistory: {
      startDate: filterData?.etdStartDate,
      endDate: filterData?.etdEndDate,
      history: {
        itemKey: "ETD",
        itemKeyIndex: 0,
      },
    },
  });

  const getItemKey = useMemo(() => {
    if (filterData.ataStartDate) {
      return "ATA";
    }
    if (filterData.etaStartDate) {
      return "ETA";
    }
  }, [filterData.ataStartDate, filterData.etaStartDate]);

  const ETAdateHistory = useMemo(() => {
    switch (getItemKey) {
      case "ATA":
        return {
          startDate: filterData.ataStartDate,
          endDate: filterData.ataEndDate,
          history: { itemKey: "ATA", itemKeyIndex: 1 },
        };
      case "ETA":
        return {
          startDate: filterData.etaStartDate,
          endDate: filterData.etaEndDate,
          history: { itemKey: "ETA", itemKeyIndex: 0 },
        };
      default:
        return undefined;
    }
  }, [filterData, getItemKey]);

  const {
    DateSearchPanel,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateSearchType,
  } = useSearchWithDateWithNoUseEffect({
    dateSearchTypeOptions: ETAdateSearchTypeOptions,
    key,
    defaultValue: getMonthFromToday(Date()),
    dateHistory: ETAdateHistory,
  });

  const dateFilterData = useMemo(() => {
    if (dateSearchType === "ETA") {
      return { etaStartDate: startDate, etaEndDate: endDate };
    }
    if (dateSearchType === "ATA") {
      return { ataStartDate: startDate, ataEndDate: endDate };
    }
  }, [endDate, startDate, dateSearchType]);

  const etdDateFilterData = useMemo(() => {
    return etdStartDate && etdEndDate ? { etdStartDate, etdEndDate } : null;
  }, [etdStartDate, etdEndDate]);

  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  const autoCompleteDefaultValue = useMemo(() => {
    const defaultValue: {
      countryName: string;
      polId: number | undefined;
      podId: number | undefined;
    } = { countryName: "", polId: undefined, podId: undefined };
    if (filterData.country) {
      defaultValue.countryName = filterData.country;
    }
    if (filterData.startPortId) {
      defaultValue.polId = filterData.startPortId;
    }
    if (filterData.endPortId) {
      defaultValue.podId = filterData.endPortId;
    }

    return defaultValue;
  }, [filterData.country, filterData.startPortId, filterData.endPortId]);

  const {
    TemplateTableAutoCompletes,
    countryName,
    polId,
    podId,
    portData,
    resetData,
  } = useTemplateTableAutoCompletes({
    countryData,
    defaultValue: autoCompleteDefaultValue,
  });

  const handleTableReset = () => {
    reset(event);
    setStartDate("");
    setEndDate("");
    setEtdStartDate("");
    setEtdEndDate("");

    resetData();
  };
  const TablePanel = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: 1,
      }}
    >
      <Box>
        {/* 공통 컴포넌트 내 스타일이 적용되어 있어 한번 더 감싸서 사용 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Box>
            <ETDDateSearchPanel />
          </Box>

          <Box>
            <DateSearchPanel />
          </Box>
        </Box>

        {/* 공통 컴포넌트 내 스타일이 적용되어 있어 한번 더 감싸서 사용 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: 1,
            marginTop: 1,
            flexWrap: "wrap",
          }}
        >
          <Box>{TermSearchPanel}</Box>
          <Box>{TemplateTableAutoCompletes}</Box>
        </Box>
      </Box>

      <Button
        onClick={handleTableReset}
        variant="contained"
        size="small"
        sx={{ mt: 1, height: "40px" }}
      >
        초기화
      </Button>
    </Box>
  );

  return {
    debouncedSearchTermWithObject,
    dateFilterData,
    etdDateFilterData,
    TablePanel,
    countryName,
    polId,
    podId,
  };
};

export default useOperationTablePanel;
