import React, { ChangeEvent, useState } from "react";
import { useAtomValue } from "jotai";

import CommentList from "@sellernote/_shared/src/admin-ui/components/CommentList";
import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared/src/jotaiStates/auth";
import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

const CommentModal = ({
  setShowsCommentModal,
  showsCommentModal,
  bidDetailId,
}: {
  setShowsCommentModal: React.Dispatch<React.SetStateAction<boolean>>;
  showsCommentModal: boolean;
  bidDetailId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { data: commentData, refetch: refetchCommentData } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementCommentList({
      bidId: bidDetailId,
    });

  const {
    mutate: updateComment,
    ResponseHandler: ResponseHandlerOfUpdateComment,
  } = TRELLO_BID_QUERY.useUpdateComment();

  const [comment, setComment] = useState("");

  const handleCommentInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleCommentUpdate = () => {
    if (!bidDetailId) {
      return;
    }

    updateComment(
      {
        pathParams: {
          bidId: bidDetailId,
        },
        comment: {
          id: currentAdminAuthInfo?.accountId || "-",
          comment,
          date: new Date().toISOString(),
        },
      },
      {
        onSuccess: () => {
          setComment("");
          handleSnackbarOpen("요청에 성공했습니다.");
          refetchCommentData();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpened={showsCommentModal}
        handleClose={() => setShowsCommentModal(false)}
        modalBody={
          commentData && (
            <CommentList
              comment={comment}
              onCommentInputChange={handleCommentInputChange}
              onCommentUpdate={handleCommentUpdate}
              commentDataList={commentData.comments}
              commentPageType="businessManagement"
            />
          )
        }
      ></Modal>

      {ResponseHandlerOfUpdateComment}
    </>
  );
};

export default CommentModal;
