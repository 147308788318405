import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Box, Button } from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";
import { AdminOrderDetail } from "@sellernote/_shared/src/types/forwarding/adminOrder";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";

export default function usePartnerButtons(
  POId: number,
  orderDetail: AdminOrderDetail
) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [showsOrderPartnerModal, setShowsOrderPartnerModal] = useState(false);

  const [showsRequestEmailToPartner, setShowsRequestEmailToPartner] =
    useState(false);

  const [businessArea, setBusinessArea] = useState<"domestic" | "foreign">();

  const handlePartnerModalOpen = (partnerDomain: PartnerBusinessArea) => {
    setBusinessArea(partnerDomain === "domestic" ? "domestic" : "foreign");
    setShowsOrderPartnerModal(true);
  };

  const {
    mutate: contactPartnerEmail,
    ResponseHandler: ResponseHandlerOfContactPartnerEmail,
  } = ADMIN_ORDER_QUERY.useContactPartner(POId);

  const handleContactPartnerEmail = useCallback(() => {
    contactPartnerEmail(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
              id: POId,
            })
          );
          handleSnackbarOpen("요청에 성공했습니다.");
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [contactPartnerEmail, queryClient, POId, handleSnackbarOpen]);

  const EditPartnerAndContactButton = useCallback(
    ({
      partnerDomain,
      hasPartner,
    }: {
      partnerDomain: PartnerBusinessArea;
      hasPartner: string | number | boolean | undefined | JSX.Element;
    }) => {
      return (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="outlined"
            size="small"
            disabled={orderDetail.poStatus === "registered"}
            onClick={() => handlePartnerModalOpen(partnerDomain)}
          >
            수정
          </Button>

          {hasPartner && (
            <Button
              variant="contained"
              size="small"
              disabled={orderDetail.poStatus === "registered"}
              onClick={handleContactPartnerEmail}
            >
              컨택
            </Button>
          )}
        </Box>
      );
    },
    [handleContactPartnerEmail, orderDetail.poStatus]
  );

  return {
    EditPartnerAndContactButton,
    showsOrderPartnerModal,
    showsRequestEmailToPartner,
    setShowsOrderPartnerModal,
    setShowsRequestEmailToPartner,
    businessArea,
    ResponseHandler: <>{ResponseHandlerOfContactPartnerEmail}</>,
  };
}
