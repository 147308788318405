import { Box, Typography } from "@mui/material";

import DatePicker from "@sellernote/_shared/src/admin-ui/components/DatePicker";
import { getInvoiceTypeKR } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

export default function TradingStatementHeader({
  sendDate,
  invoiceType,
  shipmentId,
  invoiceIssueDate,
  setInvoiceIssueDate,
}: {
  sendDate: string | undefined;
  invoiceType: string;
  shipmentId: number;
  invoiceIssueDate: string | null;
  setInvoiceIssueDate: (date: string | null) => void;
}) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h5" fontWeight={600} className="sads">
        {getInvoiceTypeKR(invoiceType)} 생성 {shipmentId}
      </Typography>

      <Box display="flex" alignItems="center" gap={5}>
        {sendDate && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography
              className="sads"
              fontWeight={500}
              color="primary"
              display={"inline"}
            >
              고객사 발송일:
            </Typography>

            <Typography className="sads" display={"inline"}>
              {sendDate}
            </Typography>
          </Box>
        )}

        <Box display="flex" alignItems="center" gap={1}>
          <Typography className="sads" color="primary" fontWeight={500}>
            서류상 발행일:
          </Typography>

          <DatePicker
            value={invoiceIssueDate}
            setDate={(date) => {
              setInvoiceIssueDate(date);
            }}
            when={"start"}
          />
        </Box>
      </Box>
    </Box>
  );
}
