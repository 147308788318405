import AdminDetailDescription from "@sellernote/_shared/src/admin-ui/components/AdminDetailDescription";
import { AdminOrderDetail } from "@sellernote/_shared/src/types/forwarding/adminOrder";

function SmeInfo({ orderDetail }: { orderDetail: AdminOrderDetail }) {
  return (
    <AdminDetailDescription
      title={"운송견적 요청 내역"}
      descriptionValueList={[
        {
          label: "운송방식",
          value: orderDetail.freightTypes.join(","),
          gridSize: 6,
        },
        {
          label: "인코텀즈",
          value: orderDetail.incoterms,
          gridSize: 6,
        },
        {
          label: "선적항(POL)",
          value: orderDetail.pol,
          gridSize: 6,
        },
        {
          label: "도착항(POD)",
          value: orderDetail.pod,
          gridSize: 6,
        },
      ]}
    />
  );
}

export default SmeInfo;
