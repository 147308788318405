import { Button } from "@mui/material";

import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

const MAX_DOWNLOAD_COUNT = 2000;

const PurchaseListExcelDownload = ({
  listTotal,
  fetchListParams,
}: {
  listTotal: number;
  fetchListParams: GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ;
}) => {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const {
    refetch: refetchExcelData,
    isLoading: isExcelDownloading,
    ResponseHandler: ResponseHandlerOfGetAdminSettlementPurchaseExcelList,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementPurchaseExcelList(
    fetchListParams
  );

  const handleExcelDownload = () => {
    if (listTotal === 0) {
      showSnackbar("다운로드할 데이터가 없습니다.", "error");
      return;
    }

    if (listTotal > MAX_DOWNLOAD_COUNT) {
      showSnackbar(
        `${MAX_DOWNLOAD_COUNT}건 이상의 데이터는 엑셀 다운로드가 불가능합니다.`,
        "error"
      );
      return;
    }

    refetchExcelData();
  };

  return (
    <>
      <Button
        variant="contained"
        className="sads"
        disabled={isExcelDownloading}
        onClick={handleExcelDownload}
      >
        엑셀 다운로드
      </Button>

      {ResponseHandlerOfGetAdminSettlementPurchaseExcelList}
    </>
  );
};

export default PurchaseListExcelDownload;
