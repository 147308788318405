import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";

import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import useSearchWithDate from "@sellernote/_shared/src/hooks/admin/useSearchWithDate";
import useSearchWithTerm from "@sellernote/_shared/src/hooks/admin/useSearchWithTerm";
import useSelectFilter, {
  SelectFilterOptionList,
} from "@sellernote/_shared/src/hooks/admin/useSelectFilter";
import { BooleanStringV2 } from "@sellernote/_shared/src/types/common/common";

import {
  BOOLEAN_SELECT_FILTER_OPTION_LIST,
  DATE_SEARCH_TYPE_OPTION_LIST,
  INVOICE_SHIPMENT_TYPE_FILTER_LIST,
  INVOICE_STATUS_SELECT_FILTER_OPTION_LIST,
  INVOICE_TYPE_SELECT_FILTER_OPTION_LIST,
  TERM_SEARCH_TYPE_OPTION_LIST,
} from "./constants";

export default function useSalesSettlementTableFilter() {
  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions: DATE_SEARCH_TYPE_OPTION_LIST,
    });

  const { TermSearchPanel, debouncedSearchTermWithObject } = useSearchWithTerm({
    termSearchTypeOptions: TERM_SEARCH_TYPE_OPTION_LIST,
  });

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: [
      { label: "전체", value: undefined },
      ...ADMIN_FORWARDING_MANAGER_OPTION_LIST,
    ],
    label: "담당자",
  });

  const {
    SelectFilterPanel: InvoiceTypeSelectFilterOptionList,
    selectFilter: invoiceTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: INVOICE_TYPE_SELECT_FILTER_OPTION_LIST,
    label: "구분",
  });

  const {
    SelectFilterPanel: HasDepositSelectFilterPanel,
    selectFilter: hasDepositSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: BOOLEAN_SELECT_FILTER_OPTION_LIST,
    label: "입금일 유무",
  });

  const {
    SelectFilterPanel: IsIssuedSelectFilterPanel,
    selectFilter: isIssuedSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: BOOLEAN_SELECT_FILTER_OPTION_LIST,
    label: "계산서 발행",
  });

  const {
    SelectFilterPanel: HasRequestIssueDateSelectFilterPanel,
    selectFilter: hasRequestIssueDateSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: BOOLEAN_SELECT_FILTER_OPTION_LIST,
    label: "고객 요청일 유무",
  });

  const {
    SelectFilterPanel: InvoiceStatusSelectFilterOptionList,
    selectFilter: invoiceStatusSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: INVOICE_STATUS_SELECT_FILTER_OPTION_LIST,
    label: "상태",
  });

  const {
    SelectFilterPanel: ShipmentTypeSelectFilterOptionList,
    selectFilter: shipmentTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: INVOICE_SHIPMENT_TYPE_FILTER_LIST,
    label: "수출입 구분",
  });

  const changeBooleanStringToBoolean = useCallback(
    (
      booleanString:
        | SelectFilterOptionList<BooleanStringV2 | undefined>
        | undefined
    ) => {
      if (booleanString?.value === "TRUE") {
        return true;
      }

      if (booleanString?.value === "FALSE") {
        return false;
      }

      return undefined;
    },
    []
  );

  const SalesSettlementTableFilter = useMemo(() => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {ForwardingManagerSelectFilterPanel}
          {TermSearchPanel}
          {DateSearchPanel}
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {InvoiceTypeSelectFilterOptionList}
          {HasDepositSelectFilterPanel}
          {IsIssuedSelectFilterPanel}
          {HasRequestIssueDateSelectFilterPanel}
          {InvoiceStatusSelectFilterOptionList}
          {ShipmentTypeSelectFilterOptionList}
        </Box>
      </Box>
    );
  }, [
    ForwardingManagerSelectFilterPanel,
    TermSearchPanel,
    DateSearchPanel,
    InvoiceTypeSelectFilterOptionList,
    HasDepositSelectFilterPanel,
    IsIssuedSelectFilterPanel,
    HasRequestIssueDateSelectFilterPanel,
    InvoiceStatusSelectFilterOptionList,
    ShipmentTypeSelectFilterOptionList,
  ]);

  return {
    SalesSettlementTableFilter,
    shipmentTypeSelectFilterValue: shipmentTypeSelectFilter?.value,
    invoiceTypeSelectFilterValue: invoiceTypeSelectFilter?.value,
    invoiceStatusSelectFilterValue: invoiceStatusSelectFilter?.value,
    hasDepositSelectFilterValue: changeBooleanStringToBoolean(
      hasDepositSelectFilter
    ),
    isIssuedSelectFilterValue:
      changeBooleanStringToBoolean(isIssuedSelectFilter),
    dateSearchType,
    startDate,
    endDate,
    debouncedSearchTermWithObject,
    forwardingManagerSelectFilterValue: forwardingManagerSelectFilter?.value,
    hasRequestIssueDateSelectFilterValue: changeBooleanStringToBoolean(
      hasRequestIssueDateSelectFilter
    ),
  };
}
